/* ================================================== */

/* Blaxcut
/* created by designesia

@charset "utf-8";

Table of content:

* body
* header
* subheader
* navigation
* content
* section
* footer
* sidebar
* blog
* products
* contact
* elements
  * button and link
  * columns
  * counter
  * divider
  * dropcap
  * heading
  * icon font
  * list
  * package
  * page heading
  * social icons
  * spacer
  * tabs
* plugins
* media query
*/

/* ================================================== */

/* ================================================== */
/* import fonts */
/* ================================================== */

@import url("https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

* {
  --title-font: "Bebas Neue", Helvetica, Arial, sans-serif;
  --body-font: "Manrope", Helvetica, Arial, sans-serif;
  --border-default: solid 1px rgba(30, 30, 30, 1);
  --bg-color-even: #e8e8e8;
  --bg-color-odd: #f4f4f4;
  --bg-color-dark: #101010;
  --body-font-color: #757575;
  --dark-body-font-color: #cccccc;
}

/* Custom code */


.gallery-img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 400px;
}



.custom-border {
  position: relative;
  padding: 20px;
  border: solid 1px var(--primary-color);
}

.custom-border .double {
  position: absolute;
  width: 100%;
  height: 100%;
  border: solid 1px var(--primary-color);
  top: 10px;
  left: 10px;
}

.footer-bg{
  background-color: #101010;
}

.dark-text {
  color: #313131 !important;
}

/* radio button */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* OTP section */
.custom-otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

/* Swiper button */

.custom-otp-input {
  width: 6rem !important;
  height: 5rem;
  color: var(--primary-color);
  font-size: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  text-align: center;
  margin: 0 5px;
}

.swiper-button-prev {
  border: 1px solid var(--primary-color) !important;
  width: 35px !important;
  padding: 5px !important;
  color: white !important;
  height: 35px !important;
  border-radius: 100% !important;
}

.swiper-button-next {
  border: 1px solid var(--primary-color) !important;
  width: 35px !important;
  padding: 5px !important;
  color: white !important;
  height: 35px !important;
  border-radius: 100% !important;
}
.slick-dots li.slick-active button:before {
  color: var(--primary-color) !important; 
  font-size: 10px;
}

.slick-dots li button:before {
  color: var(--primary-color) !important;
  font-size: 10px;
}

.swiper-pagination-bullet {
  background-color: var(--primary-color) !important; /* Change the color as desired */
}

/* Service section border */
.service-bg {
  background-color: var(--primary-color);
}

.category-border {
  border: 3px solid var(--primary-color);
  border-radius: 7px;
}

/* UserInfo component */
.info-input {
  border: 1px solid var(--primary-color);
  border-radius: 3px;
  background-color: var(--secondary-color);
}

/* Waitlist height */
.mobile-height {
  height: 600px;
}

@media (max-width: 678px) {
  .mobile-height {
    height: 450px;
  }
}

/* ================================================== */

/* custom styles */

/* ================================================== */

.de-separator:after {
  background: none repeat scroll 0% 0% var(--primary-color);
  content: "";
  height: 10px;
  right: 0%;
  margin: -5px auto auto -5px;
  position: absolute;
  transform: rotate(-45deg);
  width: 10px;
}

.de-separator:before {
  background: none repeat scroll 0% 0% var(--primary-color);
  content: "";
  height: 10px;
  left: 0%;
  margin: -5px auto auto -5px;
  position: absolute;
  transform: rotate(-45deg);
  width: 10px;
}

.de-separator {
  position: relative;
  border-top: 1px solid var(--primary-color);
  width: 250px;
  clear: both;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
}
/* header */
.header-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}


/* ================================================== */
/* body */
/* ================================================== */

html {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  background: #ffffff;
}

body {
  font-family: var(--body-font);
  font-size: 16px;
  font-weight: 400;
  color: var(--body-font-color);
  padding: 0;
  line-height: 1.8em;
  word-spacing: 0px;
  letter-spacing: -0.2px;
}

.dark-scheme {
  color: var(--dark-body-font-color);
}

body.dark-scheme {
  background: var(--bg-color-even);
}

body.boxed {
  margin: 30px;
  overflow: hidden;
  border: solid 1px #eee;
}

body .owl-stage .owl-item {
  margin-bottom: 15px;
}

#wrapper {
  position: relative;
  overflow: hidden;
}

.de-navbar-left #wrapper {
  overflow: visible;
}

a,
a:hover {
  text-decoration: none;
}

a.a-underline {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

a.a-underline span {
  display: block;
  position: relative;
  left: 0;
  bottom: 0;
  border-bottom: solid 1px #333;
  width: 0;
}

a.a-underline:hover span {
  width: 100%;
}

/* ================================================== */

/*  header  */

/* ================================================== */

header {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1001;
  background: #2b313f;
  margin: 0;
  border-bottom: solid 1px rgba(255, 255, 255, 0);
}

header .info {
  display: none;
  color: #fff;
  padding: 10px 0 10px 0;
  width: 100%;
  z-index: 100;
  background: #242424;
}

header .info .col {
  display: inline-block;
  font-size: 12px;
}

header .info .col {
  padding-right: 30px;
}

header .info .col:last-child {
  padding-right: 0px;
}

header .info i {
  font-size: 14px;
  margin-right: 15px;
}

header .info .social.col {
  border: none;
}

header .info .social i {
  color: #fff;
  font-size: 14px;
  display: inline-block;
  float: none;
  padding: 0 10px 0 10px;
  margin: 0;
}

header .info strong {
  font-size: 12px;
}

header.transparent {
  background: none;
}

body:not(.side-layout) header:not(.smaller):not(.header-mobile).header-light.transparent {
  background: rgba(255, 255, 255, 0);
}

header.autoshow.scrollOn,
header.autoshow.scrollOff {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

header.autoshow.scrollOn {
  top: 0;
}

header.autoshow.scrollOff {
  top: -120px;
}

header div#logo {
  color: white;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

header .logo,
header .logo-2 {
  font-family: var(--body-font);
  color: #fff;
  font-size: 40px;
  font-weight: 300;
}

header .logo-2 {
  color: #333;
}

header.header-full nav {
  float: left;
}

header nav a {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

header nav a:hover {
  color: #555;
}

header.smaller #mainmenu ul ul {
  top: 0px;
}

header.header-center.scroll-down {
  margin-top: -175px;
}

header.header-center #logo-center {
  display: inline-block;
  padding-top: 40px;
}

header.header-center #mainmenu {
  display: inline-block;
}

header.header-center {
  text-align: center;
}

header.header-center #logo {
  display: none;
}

.dark-scheme header.smaller {
  background: #101010;
}

header.smaller .btn-rsvp {
  height: 100px;
  padding-top: 55px;
}

.boxed header.smaller {
  margin-top: 0px;
}

header.smaller .logo-init {
  display: none;
}

header.smaller #topbar.topbar-dark {
  background: rgba(34, 34, 34, 0.05);
}

header.smaller #topbar.topbar-dark a {
  color: #333333;
}

header .logo-mobile {
  display: none;
}

header.smaller.scroll-dark {
  background: var(--bg-color-even);
}

header.header-light .h-phone,
header.smaller.scroll-light .h-phone {
  color: #202020;
}

header:not(.header-light).smaller.scroll-light div#logo .logo {
  display: none;
}

header.smaller.scroll-light div#logo .logo-2 {
  display: inline-block;
}

header.smaller.scroll-light div#logo .logo-scroll {
  display: inline-block;
}

header.smaller.header-light div#logo .logo {
  display: none;
}

header.smaller.scroll-light .social-icons i {
  color: #333;
}

header.smaller.scroll-light #mainmenu li a,
header.smaller.scroll-light #mainmenu li.has-child:after {
  color: #333333;
}

header.smaller.scroll-light #mainmenu li li a:hover {
  color: #ffffff;
}

.logo-smaller div#logo {
  width: 150px;
  height: 30px;
  line-height: 65px;
  font-size: 30px;
}

.logo-smaller div#logo img {
  font-size: 30px;
}

header.smaller div#logo .logo-1 {
  display: none;
}

header.smaller div#logo .logo-2 {
  display: inline-block;
}

.logo-small {
  margin-bottom: 20px;
}

header.de_header_2 {
  height: 125px;
}

header.de_header_2.smaller {
  height: 70px;
}

header.de_header_2.smaller .info {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

header.header-full {
  padding-left: 40px;
  padding-right: 40px;
}

header .header-row {
  text-align: center;
}

header .header-col {
  position: relative;
  height: 100%;
}

/* left col header */
header .header-col.left {
  float: left;
  padding: 0 30px 0 0;
  /* border-right:solid 1px rgba(255,255,255,.2); */
}

/* middle col header */
header .header-col.mid {
  float: right;
}

header .header-col.mid .social-icons {
  float: right;
  /* border-left: solid 1px rgba(255, 255, 255, 0.2); */
  padding: 0 30px 0 30px;
}

/* subheader */

#subheader {
  text-align: center;
  overflow: hidden;
  padding: 50px 0 120px 0;
  position: relative;
}

#subheader h1 {
  font-size: 48px;
  margin-top: 100px;
  margin-bottom: 10px;
  letter-spacing: 1rem;
  margin-left: 0.5rem;
}

.de-navbar-left #subheader {
  padding-bottom: 120px;
}

#subheader.text-light .crumb {
  color: #fff;
}

#subheader .small-border-deco span {
  display: block;
  margin: 0 auto;
  height: 2px;
  width: 100px;
}

#subheader.dark h2,
#subheader.dark h4 {
  color: #fff;
}

#subheader.dark h2 {
  font-size: 48px;
}

#subheader.s2 {
  text-align: left;
  padding: 120px 0 20px;
  border: solid 1px #ddd;
  border-left: none;
  border-right: none;
}

.rtl #subheader.s2 {
  text-align: right;
}

#subheader.s2 h1,
#subheader.s2 .crumb {
  margin: 0;
  padding: 0;
}

#subheader.s2 .crumb {
  float: right;
  margin-top: 10px;
}

.rtl #subheader.s2 .crumb {
  float: left;
}

#subheader.sh-plain {
  padding: 100px 0 60px 0;
}

.de-navbar-left.de_light #subheader h1 {
  color: #222;
}

#profile_banner {
  min-height: 360px;
}

/* ================================================== */

/* navigation */

/* ================================================== */

#mainmenu {
  font-family: var(--title-font);
  font-size: 15px;
  margin: 0 auto;
  float: none;
}

#mainmenu.s2 {
  font-family: var(--body-font);
}

#mainmenu ul {
  margin: 0px 0px;
  padding: 0px;
  height: 30px;
  background: #ffffff;
  color: var(--body-font-color);
}

#mainmenu ul:not(.mega) {
  border: solid 1px #dddddd;
}

.dark-scheme #mainmenu ul {
  background: #101010;
  border: solid 1px rgba(255, 255, 255, 0.1);
}

.dark-scheme #mainmenu ul.mega {
  background: #101010;
}

#mainmenu li {
  margin: 0px 0px;
  padding: 0px 0px;
  float: left;
  display: inline;
  list-style: none;
  position: relative;
}

#mainmenu>li {
  margin-right: 30px;
}

#mainmenu>li.has-child:after {
  font-family: FontAwesome;
  content: "\f078";
  padding-left: 5px;
  font-size: 8px;
  position: relative;
  top: -2px;
}

.rtl #mainmenu li.has-child:after {
  padding-left: 15px;
}

.header-light #mainmenu li.has-child:after {
  color: rgba(0, 0, 0, 0.5);
}

#mainmenu li>a {
  padding-right: 6px;
  letter-spacing: 0.35em;
}

#mainmenu.s2 li>a {
  letter-spacing: 0;
}

#mainmenu li.has-child>a {
  padding-right: 0px;
}

#mainmenu a {
  position: relative;
  display: inline-block;
  padding: 30px 0;
  text-decoration: none;
  color: #ffffff;
  text-align: center;
  outline: none;
}

#mainmenu p {
  line-height: 1.8em;
  margin-bottom: 0;
}

header.header-light #mainmenu a {
  color: #35404e;
}

#mainmenu a span:not(.badge) {
  position: relative;
  width: 0%;
  color: #ffffff;
  display: block;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 14px;
  border-bottom: solid 2px #ffffff;
}

#mainmenu li:hover a span:not(.badge) {
  width: 100%;
}

#mainmenu li li a span:not(.badge) {
  float: right;
  margin-top: 5px;
}

#mainmenu a.active span:not(.badge) {
  width: 100%;
  margin-left: 0%;
}

.header-light {
  border-bottom: solid 1px rgba(255, 255, 255, 0);
}

.header-light #mainmenu>li>a {
  color: #222;
}

#mainmenu li li {
  font-family: var(--body-font);
  font-size: 14px;
}

#mainmenu li li:last-child {
  border-bottom: none;
}

#mainmenu li li a {
  padding: 5px 15px;
  border-top: none;
  color: #606060;
  width: 100%;
  border-left: none;
  text-align: left;
  font-weight: normal;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  letter-spacing: 0;
}

#mainmenu li:last-child>a {
  border-bottom: none;
}

.dark-scheme #mainmenu li li a {
  color: #ffffff;
}

.rtl #mainmenu li li a {
  text-align: right;
}

#mainmenu li li a:hover {
  color: #111;
}

#mainmenu li li a:after {
  content: none;
}

#mainmenu li li a:hover,
#mainmenu ul li:hover>a {
  color: #fff;
}

#mainmenu li ul {
  width: 190px;
  height: auto;
  position: absolute;
  left: 0px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  border-radius: 3px;
}

#mainmenu ul li b {
  background: #333;
  color: #ffffff;
  padding: 0 3px;
  padding-bottom: 3px;
  font-size: 10px;
  margin-right: 3px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

#mainmenu li li {
  font-size: 14px;
  display: block;
  float: none;
  text-transform: none;
}

#mainmenu li:hover>ul {
  visibility: visible;
  opacity: 1;
  margin-top: 0px;
}

/* before edited 
#mainmenu li ul ul {
    left: 100%;
    top: 0px;
}
*/

#mainmenu li ul ul {
  top: 0px;
  margin-left: 188px;
}

#mainmenu select {
  padding: 10px;
  height: 36px;
  font-size: 14px;
  border: none;
  background: #ff4200;
  color: #eceff3;
}

#mainmenu select option {
  padding: 10px;
}

#mainmenu .btn-type {
  padding: 0;
  margin: 0;
}

#mainmenu .btn-type a {
  background: #eee;
  padding: 0;
  margin: 0;
}

#mainmenu>li ul.mega {
  position: fixed;
  left: 0;
  height: 0%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: none;
}

#mainmenu>li ul.mega .sb-menu {
  background: #fff;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border: solid 1px #dddddd;
}

.dark-scheme #mainmenu>li ul.mega .sb-menu {
  background: #101010;
  border-color: rgba(255, 255, 255, 0.1);
}

#mainmenu li:hover ul.mega {
  visibility: visible;
  opacity: 1;
}

#mainmenu li ul.mega>li,
#mainmenu li ul.mega>li a {
  width: 100%;
}

#mainmenu li ul.mega li.title {
  padding: 0px 10px 15px 10px;
  text-align: left;
  color: #fff;
  font-weight: bold;
}

#mainmenu li ul.mega>li ul {
  position: static;
  visibility: visible;
  opacity: 1;
  left: 0;
  float: none;
  width: 100%;
}

#mainmenu ul.mega>li ul li {
  margin: 0;
  padding: 0;
}

#mainmenu ul.mega>li ul li:last-child {
  border-bottom: none;
}

#mainmenu li ul.mega .menu-content {
  background: #171a21;
  padding: 30px;
}

#mainmenu li ul.mega li.title {
  font-family: var(--main-font);
  padding: 0px;
  text-align: left;
  font-weight: bold;
  color: #586383;
}

#mainmenu ul.mega>li ul {
  border: none;
}

#mainmenu li ul.mega ul li a {
  background: none;
  padding: 2px 0;
}

#mainmenu li ul.mega ul li a:hover {
  color: #35404e;
}

#mainmenu .box-icon {
  margin-bottom: 10px;
  line-height: 1.6em;
  background: rgba(var(--primary-color-rgb), 0.1);
  padding: 15px;
  margin: 0;
  height: 100%;
}

#mainmenu .box-icon:hover {
  background: rgba(var(--secondary-color-rgb), 0.2);
}

.menu-col {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
}

.menu-col i {
  margin-right: 10px;
  text-align: center;
  min-width: 20px;
}

.menu-col li {
  margin-bottom: 10px;
}

.menu-col li a {
  padding: 10px 16px;
  display: block;
  color: #333333;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.menu-col li a.active,
.menu-col li a.active i {
  color: #ffffff;
}

header.header-mobile #mainmenu>li ul.mega {
  visibility: visible;
  opacity: 1;
}

header:not(.header-mobile) #mainmenu>li:hover ul.mega {
  height: auto;
}

/* menu toggle for mobile */

#menu-btn {
  display: none;
  float: right;
  width: 32px;
  height: 32px;
  padding: 4px;
  text-align: center;
  cursor: poInter;
  color: #fff;
  margin-top: 0px;
  margin-left: 20px;
  line-height: 1.5em;
}

#menu-btn:before {
  font-family: FontAwesome;
  content: "\f0c9";
  font-size: 20px;
}

#menu-btn:hover {
  background: var(--primary-color);
}

#menu-btn:hover:before {
  color: #222;
}

.rtl #menu-btn {
  float: left;
}

header.header-mobile.header-light,
header.header-mobile.header-light #menu-btn,
header.header-mobile.header-light #menu-btn:hover {
  background: none;
}

header.header-mobile.header-light #menu-btn {
  color: #222;
}

header.header-mobile.header-center #logo {
  display: block;
}

header.header-mobile.header-center #logo-center {
  display: none;
}


/* ================================================== */
/* Waitlist component */
/* ================================================== */

.waitlist-box {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
@media (max-width: 767px) {
  .mobile-view {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 260px;
  }
}










/* user account menu close */

/* ================================================== */

/* content */

/* ================================================== */

#content {
  width: 100%;
  background: #ffffff;
  padding: 90px 0 90px 0;
  z-index: 100;
}

.de_light #content {
  background: #ffffff;
}

#content {
  background: #ffffff;
}

.dark-scheme #content {
  background: var(--bg-color-even);
}

/* ================================================== */

/* section */

/* ================================================== */

section {
  padding: 120px 0 120px 0;
  position: relative;
}

#content.zebra section:nth-child(even) {
  background: #fcfcfc;
}

section:not(.relative) {}

section.no-bg,
#content.no-bg {
  background: none !important;
}

.dark-scheme section {
  background: #101010 !important;
}

.no-bg {
  background: none !important;
}

.bgcolor-variation {
  background: #f6f6f6;
}

.overlay-gradient {
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  padding: 90px 0 90px 0;
}

.de-navbar-left .container-fluid .container {
  width: 100%;
}

.de-navbar-left section {
  padding: 70px;
}

.de_light section {
  background: #ffffff;
}

.section-fixed {
  position: fixed;
  width: 100%;
  min-height: 100%;
  height: auto;
}

.full-height {
  overflow: hidden;
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.text_top {
  position: relative;
  z-index: 1000;
}

/* ================================================== */

/* footer */

/* ================================================== */

footer {
  /*background:url('../images/logo-big.png') -50% center no-repeat var(--primary-color) !important;*/
  padding: 30px 0 30px 0;
  font-size: 15px;
}

footer {
  background: var(--bg-color-dark);
}

footer.footer-light {
  background: #ffffff;
}

footer.footer-light a {
  color: #5d6371;
}

footer p {
  line-height: 1.8em;
}

.dark-scheme footer a {
  color: var(--dark-body-font-color);
}

footer.footer-black {
  background: #222222;
}

footer h5,
.de_light footer h5 {
  color: #fff;
  font-size: 18px;
  text-transform: none;
}

footer a {
  color: #ffffff;
  text-decoration: none !important;
}

.de-navbar-left footer {
  padding: 70px 70px 0 70px;
}

.subfooter {
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  padding: 20px 0 20px 0;
}

.footer-light .subfooter {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.de-navbar-left .subfooter {
  background: none;
}

header.side-header {
  border-bottom: none;
  margin: 0;
}

header.side-header .social-icons-2 {
  margin: 0 auto;
  margin-top: 50px;
  float: none;
  text-align: center;
}

header.side-header .social-icons-2 a {
  display: inline-block;
  margin: 0 2px 0 2px;
  background: #555;
  width: 36px;
  height: 36px;
  padding-top: 5px;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  opacity: 0.2;
}

.de_light header.side-header .social-icons-2 a {
  background: #fff;
  color: #333;
}

header.side-header .social-icons-2 a:hover {
  opacity: 1;
}

.de-navbar-left.de_light header,
.de-navbar-left.de_light {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

header.header-light-transparent {
  background: rgba(255, 255, 255, 0.5);
}

header.header-solid.header-light #mainmenu>li>a {
  color: #555;
}

header.header-solid.header-light .info {
  background: #eee;
  color: #555;
}

header.header-solid.header-light .info .social i {
  color: #222;
}

header.header-solid {
  background: #fff;
}

header.smaller.header-dark {
  background: #222222;
  border: none;
}

header.smaller.header-dark #mainmenu a {
  color: #fff;
}

header.s2 {
  background: #ffffff !important;
  border-bottom: solid 1px #eee;
  top: 0;
}

header.s2 #mainmenu>li>a {
  padding-top: 18px;
  padding-bottom: 18px;
}

.side-layout header.smaller.header-dark #mainmenu>li {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.side-layout header.smaller.header-dark #mainmenu>li:last-child {
  border-bottom: none;
}

header.header-mobile {
  position: fixed !important;
  background: #fff;
  top: 0;
  padding-bottom: 10px;
}

header.header-mobile.menu-open {
  background: #ddd;
}

header.header-mobile .container {
  max-width: 100% !important;
  padding-left: 20px;
  padding-right: 20px;
}

.dark-scheme header.header-mobile {
  background: #101010;
}

header.header-mobile.has-topbar .header-row,
header.header-mobile.has-topbar .header-col {
  margin-top: 10px;
}

header.header-mobile nav {
  float: none;
}

header.header-mobile .logo {
  display: none;
}

header.header-mobile .btn-rsvp {
  padding-top: 40px;
  height: 90px;
}

header.header-mobile .logo-2 {
  display: inline-block;
}

header.header-mobile.header-light {
  background: #fff;
}

header.header-mobile #mainmenu ul {
  padding: 0;
}

header.header-solid.header-light {
  background: #fff;
}

header.header-light .logo {
  display: none;
}

header.header-light .logo-2 {
  display: inline-block;
}

/* topbar start here */

#topbar {
  z-index: 1000;
  width: 100%;
  overflow: hidden;
  top: 0;
  background: rgba(0, 0, 0, 0.05);
}

#topbar.topbar-dark {
  background: rgba(34, 34, 34, 0.2);
}

#topbar a {
  color: #323232;
  font-weight: 500;
  text-decoration: none;
}

#topbar.text-light a {
  color: #ffffff;
}

.topbar-left,
.topbar-right {
  display: flex;
}

.topbar-right {
  float: right;
}

.topbar-solid {
  background: #ffffff;
}

.tb-light {
  background: #ffffff;
}

#topbar:not(.topbar-noborder) .topbar-right .topbar-widget {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-right span:last-child {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-right .topbar-widget {
  border-left: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-right span:last-child {
  border-right: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .tb-light .topbar-right .topbar-widget {
  border-left: solid 1px rgba(0, 0, 0, 0.1);
}

#topbar:not(.topbar-noborder) .tb-light .topbar-right span:last-child {
  border-right: solid 1px rgba(0, 0, 0, 0.1);
}

/* topbar left content */
.topbar-left {
  float: left;
}

#topbar:not(.topbar-noborder) .topbar-left .topbar-widget {
  border-right: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-left .topbar-widget {
  border-right: solid 1px #eee;
}

#topbar:not(.topbar-noborder) .topbar-left span:first-child {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

#topbar:not(.topbar-noborder) .tb-light .topbar-left .topbar-widget {
  border-right: solid 1px rgba(0, 0, 0, 0.1);
}

#topbar:not(.topbar-noborder) .tb-light .topbar-left span:first-child {
  border-left: solid 1px rgba(0, 0, 0, 0.1);
}

.h-phone {
  position: relative;
  font-weight: bold;
  color: #000000;
  font-family: var(--title-font);
  padding-left: 40px;
  font-size: 20px;
  padding-top: 22px;
}

.h-phone span {
  display: inline-block;
  position: absolute;
  left: 40px;
  top: 0;
  font-size: 14px;
  font-weight: 500;
}

.h-phone i {
  display: inline-block;
  position: absolute;
  font-size: 40px;
  left: 0;
  top: 12px;
}

/* topbar widget */
.topbar-widget {
  font-size: 14px;
  display: flex;
  padding: 3px 15px;
  font-weight: 400;
  height: 40px;
}

.topbar-widget.widget-text {
  padding-top: 6px;
}

.topbar-widget i {
  font-size: 16px;
  margin-top: 8px;
  margin-right: 10px;
}

.h-sub .topbar-widget {
  padding: 12px;
}

.topbar-widget:before {
  float: left;
  position: relative;
  font-family: "FontAwesome";
  font-size: 16px;
  margin-right: 10px;
}

.topbar-widget.tb-phone:before {
  content: "\f095";
}

.topbar-widget.tb-email:before {
  content: "\f003";
}

.topbar-widget.tb-opening-hours:before {
  content: "\f017";
}

.topbar-widget.tb-social {
  padding: 0;
}

.topbar-widget.tb-social a {
  font-size: 14px;
  display: flex;
  padding: 5px;
  padding-left: 15px;
  display: inline-block;
  text-align: center;
}

.topbar-widget a span {
  font-weight: bold;
  margin-right: 10px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

#topbar:not(.topbar-noborder) .topbar-light .topbar-widget.tb-social a,
#topbar:not(.topbar-noborder) .topbar-light .topbar-widget.tb-social a:first-child {
  border-left: solid 1px rgba(0, 0, 0, 0.1);
}

#topbar:not(.topbar-noborder) .topbar-widget.tb-social a {
  border-left: solid 1px rgba(255, 255, 255, 0.1);
}

.tb-light .topbar-widget.tb-social a {
  border-left: solid 1px rgba(0, 0, 0, 0.1);
}

.topbar-widget.tb-social a:first-child {
  border: none;
}

/* topbar end here */

#de-sidebar {
  padding: 0px;
}

/* ================================================== */

/* elements */

/* button and link */

/* ------------------------------ */

a {
  text-decoration: none;
}

.de_light a {
  color: #888;
}

.de_light a:hover {
  color: #555;
}

a.btn-text {
  text-decoration: none;
  display: inline-block;
  color: #111;
  font-weight: 600;
  padding: 0;
}

a.btn-text:after {
  font-family: FontAwesome;
  content: "\f054";
  padding-left: 10px;
}

a.btn-text {
  color: #fff;
}

a.btn-big {
  font-size: 14px;
  color: #eceff3;
  letter-spacing: 1px;
  line-height: normal;
  font-weight: bold;
  text-transform: uppercase;
  border: solid 1px #fff;
  padding: 10px 30px 10px 30px;
}

a.btn-big:after {
  font-family: FontAwesome;
  content: "\f054";
  margin-left: 20px;
}

a.btn,
.btn {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

a.btn:before {
  content: "";
  background: rgba(0, 0, 0, 0);
  width: 0;
  height: 100%;
  position: absolute;
  z-index: -1;
}

a.btn-fx:after {
  font-family: FontAwesome;
  content: "\f178";
  margin-left: 15px;
  position: absolute;
  right: -20px;
  margin-top: 0px;
}

a.btn-fx {
  font-size: 14px;
  color: #eceff3;
  letter-spacing: 1px;
  line-height: normal;
  font-weight: bold;
  text-transform: uppercase;
  border: solid 1px #fff;
  padding: 10px 30px 10px 30px;
}

a.btn-fx:hover {
  padding-left: 20px;
  padding-right: 40px;
}

a.btn-fx:before {
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

a.btn-fx:hover:after {
  right: 15px;
}

a.btn-fx:hover:before {
  width: 100%;
  background: rgba(0, 0, 0, 1);
}

a.btn-fx.light:hover:before {
  width: 100%;
  background: rgba(255, 255, 255, 1);
}

.btn-fullwidth {
  display: block;
  width: 100%;
}

a.btn-slider {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: normal;
  text-decoration: none;
  text-transform: uppercase;
  border: solid 2px #fff;
  padding: 10px 30px 10px 30px;
  border-radius: 60px;
}

a.btn-slider:hover {
  color: #222;
  background: #fff;
  border-color: #000;
  border: solid 2px #fff;
}

a.btn-main:hover,
.btn-main:hover {
  color: #fff;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(var(--primary-color-rgb), 0.5);
  -moz-box-shadow: 2px 2px 20px 0px rgba(var(--primary-color-rgb), 0.5);
  box-shadow: 2px 2px 20px 0px rgba(var(--primary-color-rgb), 0.5);
}

a.btn-slider:hover:after {
  color: #222;
}

a.btn-id,
a.btn-id:hover {
  border: none;
}

a.btn-light.btn-id {
  color: #222;
}

a.btn-dark.btn-id {
  color: #fff;
}

.btn-main.btn-small {
  padding: 5px 20px 5px 20px;
  font-weight: bold;
  text-transform: uppercase;
}

.btn-fx.btn-main {
  text-transform: normal;
}

a.btn-bg-dark {
  background: #222;
}

a.btn-text-light {
  color: #fff;
}

.btn-icon-left i {
  margin-right: 12px;
  color: #fff;
}

.btn-add_to_cart,
a.btn-add_to_cart {
  border: solid 1px #bbb;
  font-size: 12px;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  padding: 3px;
  padding-left: 40px;
  padding-right: 20px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: #555;
}

.btn-add_to_cart:before,
a.btn-add_to_cart:before {
  font-family: "FontAwesome";
  content: "\f07a";
  position: absolute;
  left: 20px;
}

a.btn-main,
a.btn-main:active,
a.btn-main:focus,
a.btn-main:visited,
.btn-main,
input[type="button"].btn-main,
a.btn-line,
#mainmenu li ul.mega a.btn-main {
  display: inline-block;
  font-family: var(--title-font);
  text-align: center;
  color: #fff;
  outline: 0;
  font-weight: 800;
  text-decoration: none;
  padding: 2px 20px 2px 20px;
  font-size: 14px;
  border: none;
  letter-spacing: 0.35em;
}

a.btn-main img {
  width: 24px;
  margin-right: 10px;
  margin-top: -2px;
}

a.btn-sc {
  display: inline-block;
  font-family: var(--title-font);
  font-weight: bold;
  padding: 6px;
  font-size: 14px;
  color: #222;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  background: #f2f2f2;
}

a.btn-sc img {
  width: 20px;
  margin-top: -4px;
  margin-right: 8px;
}

a.btn-line,
a.btn-line:hover {
  background: none;
  color: #35404e;
  border: solid 2px rgba(0, 0, 0, 0.1);
}

a.btn-line:hover {
  color: #ffffff;
  border-color: rgba(0, 0, 0, 0) !important;
}

header:not(.header-light) a.btn-line,
.dark-scheme a.btn-line,
.dark-schem a.btn-line:hover {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0.1);
}

a.btn-main.btn-white {
  background: #ffffff;
}

header a.btn-main i {
  display: none;
}

.col-right a.btn-main {
  font-size: 14px;
  text-transform: none;
}

a.btn-border {
  border: solid 2px rgba(255, 255, 255, 0.3);
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  min-width: 120px;
  outline: 0;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 30px;
  min-width: 120px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}

a.btn-border:hover {
  color: #ffffff;
  border-color: rgba(255, 255, 255, 0);
  margin-top: -2px;
  margin-bottom: 2px;
  box-sizing: border-box;
}

a.btn-border.light {
  border: solid 1px #ffffff;
  color: #ffffff;
}

a.btn-border.light:hover {
  background: #ffffff;
}

a.btn-border:hover a {
  color: #ffffff !important;
}

.d-btn-close {
  color: #ffffff;
  cursor: poInter;
  text-align: center;
  display: block;
  text-align: center;
  width: 60px;
  height: 60px;
  background: #333;
  font-size: 32px;
  font-weight: bold;
  text-decoration: none;
  margin: 0 auto;
  padding-top: 12px;
  position: absolute;
  left: 0;
  right: 0;
}

a.btn-link {
  display: block;
  text-decoration: none;
  margin-top: 10px;
}

.cover a.btn-link:hover {
  color: #fff;
}

.play-button {
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  color: #fff;
  text-decoration: none;
  border: solid 5px rgba(255, 255, 255, 0.3);
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
  padding-top: 22px;
  padding-left: 5px;
}

.play-button:before {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f04b";
  position: relative;
  color: #fff;
}

.play-button:hover {
  border: solid 5px rgba(255, 255, 255, 1);
}

.play-button.dark {
  color: #222;
  border: solid 5px rgba(0, 0, 0, 0.1);
}

.play-button.dark:before {
  color: #222;
}

.play-button.dark:hover {
  border: solid 5px rgba(0, 0, 0, 0.5);
}

.text-light .play-button {
  border-radius: 60px;
  -moz-border-radius: 60px;
  -webkit-border-radius: 60px;
  color: #fff;
  text-decoration: none;
  border: solid 5px rgba(255, 255, 255, 0.3);
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 80px;
  padding-top: 22px;
  padding-left: 5px;
}

.text-light .play-button:before {
  font-family: "FontAwesome";
  font-size: 20px;
  content: "\f04b";
  position: relative;
  color: #fff;
}

.text-light .play-button.dark {
  color: #fff;
  border: solid 5px rgba(255, 255, 255, 0.3);
}

.text-light .play-button.dark:before {
  color: #fff;
}

.text-light .play-button.dark:hover {
  border: solid 5px rgba(255, 255, 255, 1);
}

/* counter */

/* ------------------------------ */

.de_count {
  padding: 0px 0 10px 0;
  background: none;
}

.de_count h3 {
  font-size: 40px;
  letter-spacing: 0.1px;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  line-height: 1em;
}

.de_count h5 {
  font-size: 14px;
  font-weight: 500;
}

.de_count p.d-small {
  margin-top: 5px;
  opacity: 0.5;
}

.rtl .de_count h3,
.rtl .de_count h5 {
  text-align: right;
}

.de_count h3 span {
  font-size: 36px;
}

.de_count.big h3 {
  font-size: 120px;
}

.de_count span {
  font-size: 12px;
  text-transform: uppercase;
}

.de_count i {
  display: block;
  font-size: 30px;
  margin: 0 auto;
  margin-bottom: 0px;
  width: 100%;
  font-weight: lighter;
}

.de_count.small h4 {
  font-size: 28px;
  margin-bottom: 0;
}

.de_count {
  font-size: 16px;
  background: #ffffff;
  padding: 30px 20px 20px 30px;
  color: #35404e;
  font-weight: 500;
}

/* divider */

/* ------------------------------ */

.spacer-single {
  width: 100%;
  height: 30px;
  display: block;
  clear: both;
}

.spacer-double {
  width: 100%;
  height: 60px;
  display: block;
  clear: both;
}

hr {
  display: block;
  clear: both;
  border-top: solid 1px #ddd;
  margin: 40px 0 40px 0;
}

/* heading */

/* ------------------------------ */

h1,
h2,
h4,
h3,
h5,
h6,
.h1_big,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
footer.footer-light h5 {
  margin-top: 0;
  font-family: var(--title-font);
  font-weight: 400;
  color: #323232;
}

.dark-scheme h1,
.dark-scheme h2,
.dark-scheme h4,
.dark-scheme h3,
.dark-scheme h5,
.dark-scheme h6,
.dark-scheme .h1_big,
.dark-scheme .h1,
.dark-scheme .h2,
.dark-scheme .h3,
.dark-scheme .h4,
.dark-scheme .h5,
.dark-scheme .h6 {
  color: #ffffff;
}

footer h1,
footer h2,
footer h4,
footer h4,
footer h5,
footer h6 {
  color: #fff;
}

h2.text-light,
footer h4,
footer h4,
footer h5,
footer h6 {
  color: #fff;
}

h1,
.h1 {
  font-size: 72px;
  letter-spacing: 0.2em;
  margin-bottom: 20px;
  line-height: 75px;
}

h1 .label {
  display: inline-block;
  font-size: 36px;
  padding: 0 6px;
  margin-left: 10px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

h1.s1 {
  letter-spacing: 30px;
  font-size: 26px;
}

h1.s2 {
  display: inline-block;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h1.s2 .c1 {
  font-size: 240px;
  letter-spacing: -20px;
  line-height: 0.85em;
  position: relative;
}

h1.s2 .c1 span {
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
  display: block;
  font-size: 40px;
  line-height: 1em;
  background: #fff;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  padding: 10px 20px 10px 0;
}

h1.s2 .c2 {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
  font-size: 140px;
  padding-bottom: 10px;
  margin-top: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

h1.s2 .c3 {
  font-size: 20px;
  font-weight: 500;
}

h1 .small-border {
  margin-top: 30px;
}

h1.big,
.h1_big {
  font-size: 64px;
  margin: 0;
  line-height: 70px;
}

h1.very-big,
.h1.very-big {
  font-size: 72px;
  line-height: 86px;
  letter-spacing: -3px;
}

h1.ultra-big {
  font-size: 140px;
  line-height: 150px;
  letter-spacing: -0.05em;
  margin-bottom: 0;
}

h1.ultra-big span {
  display: inline-block;
  padding-right: 5px;
  margin: 0 -12px;
  letter-spacing: 1rem;
}

h1.ultra-big span.underline span {
  display: block;
  border-bottom: solid 12px #fff;
  position: relative;
  margin-top: -5px;
}

h1.very-big-2 {
  font-size: 90px;
  letter-spacing: 25px;
  text-transform: uppercase;
  font-weight: bold;
}

.h2_title {
  font-size: 28px;
  display: block;
  margin-top: 0;
  line-height: 1.2em;
}

h2 .small-border {
  margin-left: 0;
  margin-bottom: 15px;
  width: 40px;
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 48px;
  margin-bottom: 20px;
  line-height: 64px;
  letter-spacing: 0.2em;
}

h2.style-2 {
  font-size: 30px;
  margin-bottom: 20px;
}

h2.big {
  font-size: 48px;
  line-height: 1.3em;
  margin-bottom: 0;
}

h2 .uptitle {
  font-family: var(--body-font);
  display: block;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1px;
}

h2.s1,
.h2_s1 {
  font-size: 24px;
}

h2.deco-text span {
  font-family: "Parisienne";
  display: block;
  line-height: 0.85em;
  font-weight: lighter;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h2.deco-text .md {
  font-size: 80px;
}

h2.deco-text .lg {
  font-size: 120px;
}

h2.deco-text .xl {
  font-size: 150px;
}

h3 {
  font-size: 26px;
  line-height: 1.4em;
  margin-bottom: 20px;
  letter-spacing: 0.4em;
}

h3.s2 {
  position: relative;
  font-size: 22px;
}

h3.s2:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid var(--primary-color);
  top: 14px;
  margin-left: 10px;
  width: 30px;
}

h4 {
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 26px;
  letter-spacing: 0.3em;
}

h4.teaser {
  font-weight: 300;
  font-size: 22px;
}

h5.title {
  display: inline-block;
  color: #ffffff;
  padding: 2px 10px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.subtitle.s2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.subtitle.s2 span {
  margin-right: 20px;
  padding-left: 20px;
}

.subtitle.s2 i {
  margin-right: 10px;
}

.subtitle.s2 span:first-child {
  padding-left: 0;
}

h4.s1 {
  letter-spacing: 10px;
  font-weight: 400;
  font-size: 16px;
}

h4.s2 {
  font-family: var(--body-font);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.8em;
}

h4.s3 {
  font-family: "Parisienne";
  font-size: 60px;
  font-weight: lighter;
}

h4.s3 {
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 15px;
}

h5 {
  letter-spacing: 0.4em;
}

.sc-group h5 {
  background: rgba(var(--primary-color-rgb), 0.5);
  padding: 5px 15px 2px 15px;
}

.call-to-action h4 {
  text-transform: none;
  font-size: 20px;
}

h1.slogan_big {
  font-weight: 400;
  font-size: 64px;
  line-height: 64px;
  letter-spacing: -2px;
  padding: 0;
  margin: 0px 0 30px 0;
}

h1.title {
  font-size: 64px;
  letter-spacing: 10px;
}

h1.title strong {
  text-transform: none;
  letter-spacing: 0;
  font-weight: normal;
}

h1.hs1 {
  font-family: "Parisienne";
  font-size: 96px;
  display: inline-block;
  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

h1.hs2 {
  font-family: "Parisienne";
  font-size: 72px;
  display: inline-block;
  font-weight: lighter;
}

h2.subtitle {
  margin-top: 0;
}

h2.name {
  font-family: var(--body-font);
  color: #fff;
  font-size: 84px;
  line-height: 50px;
}

h2.name span {
  display: block;
  font-size: 32px;
}

h2.name-s1 {
  font-family: var(--body-font);
  color: #fff;
  font-size: 84px;
  font-weight: 700;
  line-height: 50px;
}

h2.name-s1 span {
  display: block;
  font-size: 32px;
}

h2.hw {
  display: block;
  font-family: "Parisienne";
  font-size: 48px;
  text-transform: none;
  font-weight: lighter;
}

h2.deco {
  font-family: var(--body-font);
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}

h2.deco span {
  display: inline-block;
  position: relative;
}

h2.deco span:before,
h2.deco span:after {
  content: "";
  position: absolute;
  border-top: 1px solid #bbb;
  top: 10px;
  width: 100px;
}

h2.deco span:before {
  right: 100%;
  margin-right: 15px;
}

h2.deco span:after {
  left: 100%;
  margin-left: 15px;
}

h2.hs1 {
  font-family: var(--body-font);
  font-size: 40px;
}

h2.hs1 i {
  font-size: 48px;
  position: relative;
  top: 10px;
  color: #ff0042;
  margin: 0 10px 0 10px;
}

h2.hs1 span {
  font-size: 48px;
  position: relative;
  top: 10px;
  font-family: "Miama";
  margin: 0 15px 0 10px;
  font-weight: normal;
}

h2 .de_light .text-light h2 {
  color: #fff;
}

.text-light h2.deco span:before,
.text-light h2.deco span:after {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

h2.s2 {
  font-family: var(--body-font);
  font-weight: 400;
}

h2.s3 {
  font-size: 36px;
  margin-bottom: 20px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  margin-bottom: 20px;
}

h4.style-2 {
  font-size: 18px;
}

h4.title {
  border-bottom: solid 1px #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

h5.s2 {
  display: inline-block;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  padding: 6px 12px;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  font-weight: 500;
}

h5 {
  font-size: 16px;
}

h5 span.label {
  display: inline-block;
  margin-right: 10px;
  background: #222222;
  color: #ffffff;
  padding: 0 5px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

h6 {
  font-size: 14px;
}

h4.s1 {
  font-size: 12px;
  letter-spacing: 20px;
  text-transform: uppercase;
  font-weight: 500;
}

span.teaser {
  font-family: "Georgia";
  font-style: italic;
  font-size: 18px;
}

.wm {
  width: 100%;
  color: transparent;
  -webkit-text-stroke: 1px var(--primary-color);
  font-size: 300px;
  position: absolute;
  left: 0;
  z-index: 0;
  text-align: center;
  font-family: var(--title-font);
  line-height: 70px;
  letter-spacing: 0.15em;
  top: 50%;
  transform: translateY(-50%);
}

.wm .s1 {
  -webkit-text-stroke: 0;
  color: rgba(var(--primary-color-rgb), 0.3);
}

.wm.invert {
  color: rgba(0, 0, 0, 0.1);
}

.top-center {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
  text-align: center;
  font-family: var(--title-font);
  line-height: 70px;
  letter-spacing: -0.05em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-light {
  color: #ffffff;
}

.text-dark {
  color: #223044;
}

.pricing-s2.bg.text-light .top p {
  color: #fff;
}

.text-white,
.de_light .text-white {
  color: #fff;
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6 {
  color: #fff;
}

.de_light .text-light h1,
.de_light .text-light h2,
.de_light .text-light h4,
.de_light .text-light h4,
.de_light .text-light h5,
.de_light .text-light h6 {
  color: #fff;
}

.text-white h1,
.text-white h2,
.text-white h4,
.text-white h4,
.text-white h5,
.text-white h6 {
  color: #fff;
}

.de_light .text-white h1,
.de_light .text-white h2,
.de_light .text-white h4,
.de_light .text-white h4,
.de_light .text-white h5,
.de_light .text-white h6 {
  color: #fff;
}

.de_light h1,
.de_light h2,
.de_light h4,
.de_light h4,
.de_light h5,
.de_light h6 {
  color: #202020;
}

.field-set label {
  color: #606060;
  font-weight: 500;
}

.dark-scheme .field-set label {
  color: #ffffff;
}

/* social-icons */

/* ================================================== */

.social-icons {
  display: inline-block;
}

.social-icons i {
  text-shadow: none;
  color: #fff;
  padding: 12px 10px 8px 10px;
  width: 34px;
  height: 34px;
  text-align: center;
  font-size: 16px;
  margin: 0 3px 0 3px;
  background: #ffffff;
}

.dark-scheme .social-icons i {
  background: rgba(255, 255, 255, 0.1);
}

.social-icons i.fa-google-plus {
  padding: 12px 14px 10px 6px;
}

.social-icons i:hover {
  background: #fff;
  border-color: #eceff3;
  color: #333;
}

.social-icons.big a {
  padding: 0;
  display: inline-block;
}

.social-box {
  font-size: 26px;
  width: auto;
  height: auto;
  color: #ffffff;
  background: var(--primary-color);
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  width: 48px;
  padding: 14px 0;
}

.social-box:hover {
  background: none;
}

#topbar .social-icons i {
  font-size: 14px;
  background: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
  margin-right: 1px;
  padding: 10px 5px;
  height: 30px;
  width: 30px;
  margin-top: 5px;
}

.social-box {
  margin: 0 15px 0 15px;
}

.social-box:hover {
  background: none;
}

.social-icons.s1 i {
  border: none;
  font-size: 16px;
  margin: 3px;
}

.social-icons.s1:hover i {
  background: none;
}

.social-icons.s1 {
  line-height: 0.5;
  text-align: center;
}

.social-icons.s1 .inner {
  display: inline-block;
  position: relative;
}

.social-icons.s1 .inner:before,
.social-icons.s1 .inner:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  top: 0;
  width: 100%;
}

.social-icons.s1 .inner:before {
  right: 100%;
  margin-right: 15px;
  margin-top: 20px;
}

.social-icons.s1 .inner:after {
  left: 100%;
  margin-left: 15px;
  margin-top: 20px;
}

.col-right {
  display: inline-block;
  float: right;
  margin-top: 22px;
}

/* css attributes */

/* margin top */

.mt0 {
  margin-top: 0;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt80 {
  margin-top: 80px;
}

.mt90 {
  margin-top: 90px;
}

.mt100 {
  margin-top: 100px;
}

/* margin bottom */

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb0 {
  margin-bottom: 0;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb100 {
  margin-bottom: 100px;
}

.ml0 {
  margin-left: 0;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.ml60 {
  margin-left: 60px;
}

.ml70 {
  margin-left: 70px;
}

.ml80 {
  margin-left: 80px;
}

.ml90 {
  margin-left: 90px;
}

.ml100 {
  margin-left: 100px;
}

.mr0 {
  margin-right: 0;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr50 {
  margin-right: 50px;
}

.mr60 {
  margin-right: 60px;
}

.mr70 {
  margin-right: 70px;
}

.mr80 {
  margin-right: 80px;
}

.mr90 {
  margin-right: 90px;
}

.mr100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: -100px;
}

.padding10 {
  padding: 10px;
}

.padding20 {
  padding: 20px;
}

.padding30 {
  padding: 30px;
}

.padding40 {
  padding: 40px;
}

.padding50 {
  padding: 50px;
}

.padding60 {
  padding: 60px;
}

.padding70 {
  padding: 70px;
}

.padding80 {
  padding: 80px;
}

.padding100 {
  padding: 100px;
}

.pt0 {
  padding-top: 0;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pt30 {
  padding-top: 30px;
}

.pt40 {
  padding-top: 40px;
}

.pt50 {
  padding-top: 50px;
}

.pt60 {
  padding-top: 60px;
}

.pt70 {
  padding-top: 70px;
}

.pt80 {
  padding-top: 80px;
}

.pb0 {
  padding-bottom: 0;
}

.pb10 {
  padding-bottom: 10px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb80 {
  padding-bottom: 80px;
}

.pl130 {
  padding-left: 130px;
}

.pl30 {
  padding-left: 30px;
}

.pr20 {
  padding-right: 20px;
}

.pr30 {
  padding-right: 30px;
}

/* margin top */

.mt-10 {
  margin-top: -10px;
}

.mt-20 {
  margin-top: -20px;
}

.mt-30 {
  margin-top: -30px;
}

.mt-40 {
  margin-top: -40px;
}

.mt-50 {
  margin-top: -50px;
}

.mt-60 {
  margin-top: -60px;
}

.mt-70 {
  margin-top: -70px;
}

.mt-80 {
  margin-top: -80px;
}

.mt-90 {
  margin-top: -90px;
}

.mt-100 {
  margin-top: -100px;
}

.absolute {
  position: absolute;
  z-index: 1;
}

.relative {
  position: relative;
  z-index: 1;
}

.z1000 {
  z-index: 1000;
}

.overflow-hidden {
  overflow: hidden;
}

.width100 {
  width: 100%;
}

.border1 {
  border-width: 1px;
}

.bordertop {
  border-top: solid 1px #eee;
}

.borderbottom {
  border-bottom: solid 1px #eee;
}

.pos-top {
  position: relative;
  z-index: 1000;
}

/* ================================================== */

/* anim */

/* ================================================== */

header,
.de_tab .de_nav li span,
.feature-box-small-icon i,
a.btn-line:after,
.de_count,
.social-icons a i,
.de_tab.tab_methods .de_nav li span,
.de_tab.tab_methods .de_nav li span:hover,
.de-gallery .overlay .icon i,
.de-gallery .overlay .icon i:hover,
.product img,
.product img:hover,
.mfp-close,
.mfp-close:hover,
.pic-hover .bg-overlay,
.pic-hover:hover>.bg-overlay,
i.btn-action-hide,
.pic-hover.hover-scale img,
.text-rotate-wrap,
.text-rotate-wrap .text-item,
.overlay-v,
.carousel-item .pf_text .title,
.carousel-item:hover .pf_text .title,
.carousel-item .pf_text .title .type,
.carousel-item:hover .pf_text .title .type,
.owl-slider-nav .next,
.owl-slider-nav .prev,
#back-to-top.show,
#back-to-top.hide,
.social-icons-sm i,
.social-icons-sm i:hover,
.overlay-v i,
.overlay-v i:hover,
.de_tab .de_nav li span,
#popup-box.popup-show,
#popup-box.popup-hide,
.form-underline input[type="text"],
.form-underline textarea,
.form-underline input[type="email"],
.form-underline select,
.btn-rsvp,
.d-btn-close,
.btn-main,
.overlay-v span,
.de_tab.tab_style_4 .de_nav li,
.form-subscribe input[type="text"],
#mainmenu a span,
#mainmenu a.active span,
.feature-box .inner,
.feature-box:hover .inner,
.feature-box .inner i,
.feature-box:hover .inner i,
.error_input,
.de_tab.tab_methods.style-2 .de_nav li.active .arrow,
.owl-item.active.center div blockquote,
.mask *,
.mask .cover,
.mask:hover .cover,
.mask img,
.mask:hover img,
.accordion .content,
a.btn-border,
.f_box span,
.f_box img,
.f_box.s2:hover img,
.mask .cover p,
.d-card:hover i,
.feature-box-type-2 i,
#contact_form input,
#form_subscribe input[type="text"],
.table-pricing tbody tr,
#selector .dark-mode,
#selector .dark-mode:hover,
.feature-box.f-boxed,
.feature-box.f-boxed:hover,
.fp-wrap,
.fp-wrap div,
.fp-wrap img,
.feature-box-type-2,
.feature-box.style-3 i,
.feature-box.style-3 h4,
.logo-carousel img,
.f-box,
.f-box *,
.fp-wrap *,
.d-gallery-item *,
.de-box-image *,
.de-image-hover *,
#selector *,
a.a-underline *,
.activity-filter *,
.dropdown *,
.icon-box.style-2 *,
#quick_search,
.form-border *,
.d-carousel *,
.de-card *,
.de-image-text *,
.image-container *,
.de-country,
.de-country *,
#slider-carousel *,
.de-event-item *,
.de-color-icons *,
.container-timeline *,
.d_demo_img *,
.carousel-control-next-icon,
.carousel-control-prev-icon,
.de-icon,
.de-icon-image {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}

a.btn-fx:after,
a.btn-fx:hover:after,
a.btn-fx:before,
a.btn-fx:hover:before,
#mainmenu li,
#mainmenu li ul,
.widget-post li .post-date,
.author_list_pp img,
.items_filter *,
.owl-prev,
.owl-next {
  -o-transition: 0.2s ease;
  -ms-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  outline: none;
}

.feature-box:hover i.wm {
  -o-transition: 2s ease;
  -ms-transition: 2s ease;
  -moz-transition: 2s ease;
  -webkit-transition: 2s ease;
  transition: 2s ease;
  outline: none;
}

header.header-mobile #mainmenu>li>span {
  -o-transition: none;
  -ms-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
  outline: none;
}

@-webkit-keyframes scaleIn {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

@keyframes scaleIn {
  from {
    opacity: 1;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

.scaleIn {
  -webkit-animation-name: scaleIn;
  animation-name: scaleIn;
}

@-webkit-keyframes scaleInFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

@keyframes scaleInFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(1.5, 1.5, 1.5);
    transform: scale3d(1.5, 1.5, 1.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

.scaleInFade {
  -webkit-animation-name: scaleInFade;
  animation-name: scaleInFade;
}

@-webkit-keyframes scaleOutFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

@keyframes scaleOutFade {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.75, 0.75, 0.75);
    transform: scale3d(0.75, 0.75, 0.75);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(var(--primary-color-rgb));
    transform: scale3d(var(--primary-color-rgb));
  }
}

.scaleOutFade {
  -webkit-animation-name: scaleOutFade;
  animation-name: scaleOutFade;
}

@-webkit-keyframes widthInFade {
  from {
    opacity: 0;
    width: 1px;
  }

  100% {
    opacity: 1;
    width: 100px;
  }
}

@keyframes widthInFade {
  from {
    opacity: 0;
    width: 1px;
  }

  100% {
    opacity: 1;
    width: 100px;
  }
}

.widthInFade {
  -webkit-animation-name: widthInFade;
  animation-name: widthInFade;
}

/* ================================================== */

/* video */

/* ================================================== */

video,
object {
  display: inline-block;
  vertical-align: baseline;
  min-width: 100%;
  min-height: 100%;
}

.de-video-container {
  top: 0%;
  left: 0%;
  height: 500px;
  width: 100%;
  overflow: hidden;
}

.de-video-content {
  width: 100%;
  position: absolute;
  z-index: 10;
}

.de-video-overlay {
  position: absolute;
  width: 100%;
  min-height: 100%;
  background: rgba(20, 20, 20, 0.5);
}

.de-video-overlay.dotted {
  /* background: url(../images_02/dotted.png); */
}

.video-fixed {
  width: 100%;
  position: fixed;
  top: 0;
}

/* youtube, vimeo */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.de-video-container .btn-line:hover:after,
.de-video-container a.btn-line:hover:after,
.de-video-container a.btn-line.hover:after {
  color: #eceff3;
}

.de-spot {
  padding: 0;
  margin: 0;
  position: absolute;
  vertical-align: center;
  text-align: center;
}

.de-spot span {
  position: absolute;
  display: inline-block;
  background: #ffffff;
  padding: 0 10px;
  font-size: 12px;
  font-weight: bold;
  left: 30px;
  line-height: 1.8em;
  color: #35404e;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
}

.de-circle-1 {
  width: 15px;
  height: 15px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  margin: 5px 0 0 5px;
  left: 31%;
}

.de-circle-2 {
  border: 4px solid #62bd19;
  -webkit-border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  -webkit-animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0;
  left: 31%;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

/* ================================================== */

/* Contact Form */

/* ================================================== */

.form-border input[type="text"],
.form-border textarea,
.form-border input[type="email"],
.form-border input[type="password"],
.form-border input[type="date"],
.form-border select {
  padding: 8px;
  margin-bottom: 10px;
  border: none;
  border: solid 2px #eeeeee;
  background: rgba(0, 0, 0, 0.025);
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  height: auto;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  color: #333;
}

.dark-scheme .form-border input[type="text"],
.dark-scheme .form-border textarea,
.dark-scheme .form-border input[type="email"],
.dark-scheme .form-border input[type="password"],
.dark-scheme .form-border input[type="date"],
.dark-scheme .form-border select {
  color: #ffffff;
  border: solid 1px rgba(var(--primary-color-rgb), 0.5);
}

.dark-scheme ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.dark-scheme .form-border input::-moz-input-placeholder,
.dark-scheme .form-border textarea::-moz-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.dark-scheme .form-border input::-webkit-input-placeholder,
.dark-scheme .form-border textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.form-border input[type="text"]:focus,
.form-border textarea:focus,
.form-underline input[type="email"]:focus,
.form-border input[type="date"]:focus,
.form-border select:focus {
  background: rgba(var(--secondary-color-rgb), 0.1);
}

.form-control {
  border: 1px solid var(--primary-color) !important;
}

.form-control:focus {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

/* ================================================== */

/* overide bootstrap */

/* ================================================== */

.dark-scheme .modal-header,
.nav-tabs {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}

.dark-scheme .modal-content {
  background: var(--bg-color-dark);
}

.dark-scheme .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.2);
}

.dark-scheme .accordion-body {
  background: var(--bg-color-dark);
  border-color: #292f45;
}

.nav-tabs .nav-link {
  font-weight: 600;
}

.tab-default .tab-content {
  margin-top: 20px;
}

.container-fluid {
  padding: 0px;
  margin: 0px;
}

.btn-primary {
  text-shadow: none;
  border: none;
}

.btn.btn-primary:hover {
  background: #555;
}

.img-responsive {
  display: inline-block;
}

.img-shadow {
  -webkit-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 40px 0px rgba(0, 0, 0, 0.1);
}

.img-circle {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

img.img-auto {
  width: auto;
  height: auto;
}

img.img-rounded {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.images-group-s1 img {
  width: 31.5%;
  margin-right: 2.5%;
  float: left;
  padding: 5px;
}

.images-group-s1 img:nth-child(2) {
  padding-top: 25%;
}

.images-group-s1 img:nth-child(3) {
  padding-top: 12.5%;
  margin-right: 0;
}

.box-rounded {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

.img-profile {
  border: solid 3px #fff;
}

.img-card-sm {
  width: 72px;
  background: #ffffff;
  margin: 3px;
  padding: 5px 0;
}

.label {
  font-family: "Inter", Arial, Helvetica, sans-serif;
}

table thead {
  font-weight: bold;
}

p.lead {
  font-size: 18px;
  line-height: 1.7em;
  margin-top: 0;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: #404040;
}

p.lead.big {
  font-size: 32px !important;
}

.dark-scheme p.lead {
  color: rgba(255, 255, 255, 0.8);
}

.dark-scheme p.lead.big {
  color: #ffffff;
}

p.lead.big {
  font-size: 18px;
}

p.sub {
  font-size: 14px;
}

p.p-info {
  font-size: 14px;
  margin: 0;
  padding: 0 0 10px 0;
}

p.d-small {
  font-size: 14px;
  line-height: 1.8em;
}

p.d-smaller {
  font-size: 12px;
  line-height: 1.5em;
}

.panel-group {
  opacity: 0.8;
}

.panel-heading,
.panel-body {
  background: #27282b;
  border: solid 1px #202220;
  border-radius: 0;
}

.panel-body {
  border-top: none;
}

.progress {
  background: #27282b;
}

.panel-heading h4 a {
  display: block;
}

.panel-heading h4 a:hover {
  color: #eceff3;
}

.panel-default,
.panel-collapse,
.panel-collapse,
.panel-group .panel {
  border: none;
  border-radius: 0;
}

.panel-heading {
  background: #222;
}

.table-bordered,
.table-bordered td {
  border: solid 1px #202220;
}

blockquote {
  font-family: var(--body-font);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.4em;
  border: none;
  border-left: solid 3px #333333;
  padding-left: 30px;
}

.rtl blockquote {
  border-left: none;
  border-right: solid 3px #333333;
  padding-left: 0px;
  padding-right: 30px;
}

.dark-scheme blockquote {
  color: #ffffff;
}

blockquote span {
  font-family: var(--body-font);
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  margin-top: 20px;
  color: #323232;
  opacity: 0.8;
}

.dark-scheme blockquote span {
  color: #ffffff;
}

blockquote.s2 {
  border: none;
  text-align: left;
  background: #f2f2f2;
  padding: 30px 30px 30px 60px;
  margin-top: 20px;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

blockquote.s2:before {
  font-family: "FontAwesome";
  content: "\f10e";
  font-style: normal;
  position: absolute;
  margin-left: -30px;
}

blockquote.s1 {
  padding: 0px;
}

blockquote.s1:before {
  font-family: "FontAwesome";
  content: "\f10d";
  display: block;
  color: rgba(0, 0, 0, 0.1);
  z-index: -1;
  font-size: 40px;
  top: 0;
  left: 0;
}

.collapsible-link::before {
  content: "";
  width: 14px;
  height: 2px;
  background: #333;
  position: absolute;
  top: calc(50% - 1px);
  right: 1rem;
  display: block;
  transition: all 0.3s;
}

/* Vertical line */
.collapsible-link::after {
  content: "";
  width: 2px;
  height: 14px;
  background: #333;
  position: absolute;
  top: calc(50% - 7px);
  right: calc(1rem + 6px);
  display: block;
  transition: all 0.3s;
}

.text-light .collapsible-link:before,
.text-light .collapsible-link:after {
  background: #ffffff;
}

.rtl .collapsible-link::before {
  right: auto;
  left: 0px;
}

.rtl .collapsible-link::after {
  right: auto;
  left: 6px;
}

.rtl .rtl-show {
  display: block;
}

.rtl .rtl-hide {
  display: none;
}

.collapsible-link[aria-expanded="true"]::after {
  transform: rotate(90deg) translateX(-1px);
}

.collapsible-link[aria-expanded="true"]::before {
  transform: rotate(180deg);
}

.collapsible-link.text-white {
  color: #ffffff;
}

.collapsible-link.text-white::after,
.collapsible-link.text-white::before {
  background: #ffffff;
}

.card h6 {
  font-weight: 500;
}

.card-header.text-light h6 {
  color: #ffffff;
}

.de-card {
  display: block;
  position: relative;
  overflow: hidden;
  background: #ffffff;
}

.de-card .de-image {
  position: relative;
  overflow: hidden;
}

.de-card img {
  position: relative;
}

.de-card:hover img {
  transform: scale(1.05);
}

.de-card .text {
  border: solid 1px #d2d2d2;
  padding: 35px;
  padding-bottom: 25px;
  position: relative;
}

.de-card .de-price span {
  display: inline-block;
  font-weight: bold;
  color: #ffffff;
  padding: 0 10px;
  position: absolute;
  top: 32px;
  right: 35px;
}

.domain-card {
  text-align: center;
  padding: 30px;
  border: solid 1px #eeeeee;
  position: relative;
  height: 100%;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.domain-card p {
  min-height: 60px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.5em;
}

.domain-card .d-price-before {
  text-decoration-line: line-through;
  margin-bottom: 10px;
}

.domain-card .d-price {
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 30px;
  color: #323232;
  letter-spacing: -3px;
}

.domain-card .d-price .d-atr-1,
.domain-card .d-price .d-atr-2 {
  font-size: 16px;
  letter-spacing: 0;
}

.domain-card img {
  width: 120px;
}

.box-icon {
  padding: 0;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  line-height: 1.6em;
}

a.box-icon:hover {
  color: var(--body-font-color) !important;
}

.box-icon .d-inner {
  padding-left: 56px;
}

.rtl .box-icon .d-inner {
  padding-left: 0;
  padding-right: 56px;
}

.box-icon h4 {
  font-size: 16px;
  margin-bottom: 3px;
}

.box-icon img {
  width: 42px;
  position: absolute;
}

.dark-scheme .box-icon.s2 {
  color: var(--dark-body-font-color);
}

.rtl .de-card .de-price span {
  right: auto;
  left: 35px;
}

a.de-card,
a.de-card:hover {
  color: #404040;
}

.de-card.s2 .d-price span {
  font-weight: bold;
  font-size: 20px;
}

.de-card.s2 h4 {
  margin-bottom: 20px;
}

.de-image-text {
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.de-image-text img {
  width: 100%;
  position: relative;
}

.de-image-text:hover img {
  transform: scale(1.05);
}

.de-image-text .d-text {
  color: #ffffff;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 40px;
  background: linear-gradient(0deg,
      rgba(30, 30, 30, 0) 30%,
      rgba(30, 30, 30, 1) 100%);
}

.de-image-text .d-text h4 {
  color: #ffffff;
}

.de-image-text .d-text blockquote {
  border: none;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  padding: 0;
  line-height: 1.6em;
}

.de-image-text .d-text blockquote .by {
  color: #ffffff;
  font-weight: 600;
  margin-top: 10px;
  padding-left: 30px;
}

.de-image-text .d-text blockquote .by:before {
  content: "";
  display: block;
  position: absolute;
  left: 40px;
  margin-top: 10px;
  width: 20px;
  height: 1px;
  background: #ffffff;
}

.accordion-style-1 {
  overflow: visible;
}

.accordion-style-1 .card {
  margin-bottom: 20px;
  border: none;
  -webkit-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  overflow: hidden;
}

.phone-num-big i {
  font-size: 48px;
  display: block;
}

.pnb-text {
  display: block;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.pnb-num {
  display: block;
  font-size: 36px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

/* ================================================== */

/* image position */

/* ================================================== */

.img-left {
  float: left;
  margin: 5px 15px 15px 0;
}

.img-right {
  float: right;
  margin: 5px 0 15px 15px;
}

hr {
  border-top: solid 1px #555;
}

hr.blank {
  border: none;
  margin: 20px 0 20px 0;
}

/* ---- grid ---- */

.grid {
  width: 100%;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .element-item ---- */

/* 5 columns, percentage width */

.grid-sizer {
  width: 5%;
}

.grid-item {
  position: relative;
  float: left;
  border: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

/* marquee */

.d-flex {
  display: flex;
  white-space: nowrap;
}

.de-marquee-list {
  display: flex;
  align-items: top;
  -webkit-animation: loop 40s infinite linear;
  animation: loop 40s infinite linear;
}

.de-marquee-list .d-item-txt {
  font-size: 150px;
  line-height: 100px;
  letter-spacing: 0.2em;
  padding-right: 8px;
  font-style: normal;
  font-weight: 500;
  font-family: var(--title-font);
  color: var(--primary-color);
  user-select: none;
}

.de-marquee-list .d-item-txt:nth-child(odd) {
  color: #ffffff;
}

.de-marquee-list .d-item-display {
  display: inline;
}

.de-marquee-list .d-item-block {
  width: 80px;
  height: 3px;
  margin: 0 20px;
  margin-right: 60px;
  padding: 0px;
  border-radius: 50%;
  display: inline-block;
  transform: translateY(-50px);
  background: var(--primary-color);
}

.de-marquee-list .d-item-block {
  border-radius: 0;
}

.text-light .de-marquee-list .d-item-dot {
  background: rgba(255, 255, 255, 0.3);
}

.de-marquee-list.style-2 .d-item-txt {
  font-size: 160px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.de-marquee-list.style-2 .d-item-dot {
  transform: translateY(-40px);
  background: rgba(255, 255, 255, 0.2);
}

/* ================================================== */

/* marquee */

/* ================================================== */

.de-step-s1 {
  position: relative;
  padding: 45px;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  height: 100%;
  background: -moz-linear-gradient(45deg,
      rgba(var(--primary-color-rgb), 0.1) 0%,
      rgba(var(--secondary-color-rgb), 0.3) 100%);
  background: -webkit-linear-gradient(45deg,
      rgba(var(--primary-color-rgb), 0.1) 0%,
      rgba(var(--secondary-color-rgb), 0.3) 100%);
  background: linear-gradient(45deg,
      rgba(var(--primary-color-rgb), 0.1) 0%,
      rgba(var(--secondary-color-rgb), 0.3) 100%);
}

.de-step-s1 p {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}

.de-step-s1 h4 {
  position: relative;
  z-index: 1;
}

.de-step-s1 .d-number {
  font-size: 150px;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 70px;
  color: rgba(var(--secondary-color-rgb), 0.5);
}

.border-gray {
  border: solid 1px #ddd;
}

.bg-white-50 {
  background: rgba(255, 255, 255, 0.5);
}

.bg-white-60 {
  background: rgba(255, 255, 255, 0.6);
}

.bg-white-75 {
  background: rgba(255, 255, 255, 0.75);
}

.bg-white-85 {
  background: rgba(255, 255, 255, 0.85);
}

.bg-blur {
  background: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(10px);
  -o-backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

/* marquee plugin */

.testimonials-marquee {
  display: block;
  background: #333333;
  height: 100%;
}

.testimonials-marquee .item {
  width: 360px;
  height: 100%;
  display: block;
  float: left;
  box-sizing: border-box;
  margin: 0 15px;
  top: 0;
}

.testimonials-marquee .item .de_testi blockquote {
  position: relative;
  height: 100%;
}

.testimonials-marquee .item .de_testi blockquote:before {
  top: 30px;
  color: var(--secondary-color);
}

/* ================================================== */

/* marquee plugin close */

/* ================================================== */

.de-gradient-edge-top {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%);
}

.dark-scheme .de-gradient-edge-top {
  background: linear-gradient(180deg,
      rgba(16, 16, 16, 1) 0%,
      rgba(16, 16, 16, 0) 100%);
}

.de-gradient-edge-bottom {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%);
}

.dark-scheme .de-gradient-edge-bottom {
  background: linear-gradient(0deg,
      rgba(16, 16, 16, 1) 0%,
      rgba(16, 16, 16, 0) 100%);
}

.no-border {
  border: none !important;
}

.owl-carousel.no-hide .owl-stage-outer {
  overflow: visible !important;
}

.c-item {
  position: relative;
}

.c-item_title {
  z-index: 1;
  width: 100%;
  font-size: 32px;
  letter-spacing: 0.15em;
  position: absolute;
  text-align: center;
  bottom: -15px;
  color: #ffffff;
  font-family: var(--title-font);
}

.c-item_wm {
  width: 100%;
  font-size: 150px;
  letter-spacing: 0.15em;
  position: absolute;
  text-align: center;
  bottom: -15px;
  color: #ffffff;
  font-family: var(--title-font);
  color: transparent;
  -webkit-text-stroke: 1px var(--primary-color);
}

/* ================================================== */

/* misc */

/* ================================================== */

body::-webkit-scrollbar,
#de_modal::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track,
#de_modal::-webkit-scrollbar-track {
  /* background: #dddddd; */
  background: var(--secondary-color);
}

body::-webkit-scrollbar-thumb,
#de_modal::-webkit-scrollbar-thumb {
  /* background-color: #ffffff; */
  background-color: var(--secondary-color);
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

body.dark-scheme::-webkit-scrollbar-track,
#de_modal::-webkit-scrollbar-track {
  /* background: #101010; */
  background: var(--primary-color);
}

.title-line {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 14px;
}

.title-line:before,
.title-line:after {
  content: "";
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
}

.title-line:before {
  margin: 0 20px 0 0;
}

.title-line:after {
  margin: 0 0 0 20px;
}

.dark-scheme .title-line:before,
.dark-scheme .title-line:after {
  background: rgba(255, 255, 255, 0.2);
}

.d_demo_img {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 5px;
  margin-bottom: 20px;
  -webkit-box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
  border: solid 1px rgba(var(--primary-color-rgb), 0.5);
}

.d_demo_img:hover {
  border-color: rgba(var(--primary-color-rgb), 1);
}

.d_demo_img .d-overlay {
  opacity: 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.d_demo_img .d-overlay span {
  color: #ffffff;
  background: var(--primary-color);
  padding: 5px 15px;
  font-weight: bold;
  transform: scale(1.5);
  opacity: 0;
}

.d_demo_img img {
  padding: 10px;
}

.d_demo_img:hover img {
  transform: scale(1.1);
}

.d_demo_img:hover .d-overlay {
  opacity: 1;
}

.d_demo_img:hover .d-overlay span {
  transform: scale(1);
  opacity: 1;
}

/* box-url */

.box-url,
.box-url:hover {
  position: relative;
  padding: 30px;
  background: #ffffff;
  display: block;
  border-radius: 12px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  color: var(--body-font-color);
  line-height: 1.6em;
  border: solid 2px rgba(var(--secondary-color-rgb), 0.2);
}

.box-url:hover {
  background: rgba(var(--secondary-color-rgb), 0.2);
  border: solid 2px rgba(var(--secondary-color-rgb), 0);
}

.dark-scheme .box-url {
  background: rgba(255, 255, 255, 0.05);
}

.box-url img {
  margin-bottom: 10px;
}

.box-url h4 {
  font-size: 18px;
}

.box-url p {
  margin-bottom: 0;
  padding: 0;
}

.dark-scheme .box-url p {
  color: var(--dark-body-font-color);
  margin-bottom: 0;
}

.box-url-label {
  font-weight: bold;
  position: absolute;
  right: 30px;
  color: #35404e;
  padding: 2px 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.menu-simple {
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.menu-simple li {
  display: inline;
  margin: 0 15px;
}

.de-icon {
  width: 80px;
  height: 80px;
  text-align: center;
  font-size: 40px;
  background: var(--primary-color);
  padding: 20px 0;
}

.de-icon-image {
  width: 80px;
  text-align: center;
  font-size: 40px;
  background: rgba(85, 85, 85, 0.2);
  padding: 15px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}

.de-icon-image-sm {
  width: 80px;
  text-align: center;
  font-size: 40px;
  background: rgba(85, 85, 85, 0.2);
  padding: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.de-hover-scale:hover {
  transform: scale(1.2);
  -webkit-box-shadow: 0 10px 30px rgb(0 19 87 / 25%);
  -moz-box-shadow: 0 10px 30px rgb(0 19 87 / 25%);
  box-shadow: 0 10px 30px rgb(0 19 87 / 25%);
}

.dark-scheme .box-url-label {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

.box-url.style-2 {
  text-align: center;
}

.box-url.style-2 h4 {
  font-size: 16px;
  margin-bottom: 0;
}

.box-url h3 {
  font-size: 26px;
}

.box-url .tld-domain {
  color: #323232;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.dark-scheme .box-url .tld-domain {
  color: #ffffff;
}

.box-url p.tld-subtitle {
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1em;
  font-size: 10px;
}

.box-url p.tld-price {
  font-size: 14px;
  font-weight: bold;
}

.soft-shadow {
  box-shadow: 0 0.25rem 0.5rem rgb(0 0 0 / 5%), 0 1.5rem 2.2rem rgb(0 0 0 / 10%);
}

.rtl .owl-carousel,
.rtl .bx-wrapper {
  direction: ltr;
}

.rtl .owl-carousel .owl-item {
  direction: rtl;
}

#item-carousel-big {
  padding: 0 25px;
}

.de-flex {
  display: flex;
  justify-content: space-between;
}

.de-flex>.de-flex-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

#loadmore {
  display: inline-block;
  margin-top: 10px;
}

.d-item {
  display: none;
}

.v-center {
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.force-text-center * {
  text-align: center !important;
}

.f-logo {
  margin-right: 30px;
}

.rtl .f-logo {
  margin-right: 0px;
  margin-left: 30px;
}

.text-light .card {
  color: #ffffff !important;
  background: rgba(255, 255, 255, 0.05);
}

.filter__r {
  float: right;
  cursor: pointer;
}

strong {
  font-weight: bold;
}

.text-light strong {
  color: #fff;
}

.bg-white {
  background: #ffffff;
}

.bg-white-10 {
  background: rgba(255, 255, 255, 0.1);
}

.wh80 {
  width: 80%;
  height: 80%;
}

.border-top {
  border-top: solid 1px #e5e5e5;
}

.border-bottom {
  border-bottom: solid 1px #e5e5e5;
}

.dark-scheme header.border-bottom {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1) !important;
}

.color-overlay {
  background: rgba(157, 130, 235, 0.3);
}

section .color-overlay {
  padding: 60px 0 60px 0;
}

.color-overlay-2 {
  background: rgba(26, 37, 48, 0.85);
}

#navigation {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  right: 20px;
  text-align: center;
  margin-bottom: 60px;
  z-index: 1000;
}

.nav-prev:before,
.nav-next:before,
.nav-exit:before {
  font-family: "FontAwesome";
  content: "\f177";
  color: #fff;
  padding: 20px;
  font-size: 24px;
  cursor: poInter;
}

.nav-exit:before {
  width: 100%;
  display: block;
}

.nav-next:before {
  content: "\f178";
  width: 100%;
  background: #555;
}

.nav-exit:before {
  content: "\f00d";
}

.de_light .nav-exit:before {
  color: #222;
}

.container-4 .de-team-list {
  width: 24.9%;
  float: left;
  position: relative;
}

.container-3 .de-team-list {
  width: 33.3%;
  float: left;
  position: relative;
}

.no-bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}

p {
  margin-bottom: 20px;
}

p.intro {
  color: #555;
  font-size: 16px;
  line-height: 1.5em;
}

.bg-grey {
  background: #f6f6f6;
}

.no-bottom .col-md-12 {}

.no-bottom {
  padding-bottom: 0 !important;
}

.no-top {
  padding-top: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.nopadding {
  padding: 0;
  margin: 0;
}

#filters i {
  margin-right: 10px;
}

.bg-side {
  position: relative;
  overflow: hidden;
}

.image-container {
  position: absolute;
  padding: 0;
  margin: 0;
  background-size: cover;
}

.image-container.pos-right {
  right: 0;
}

.image-container:hover .background-image {
  transform: scale(1.05);
}

.rtl .image-container.pos-right {
  right: auto;
  left: 0;
}

.image-slider {
  cursor: move;
}

.inner-padding {
  padding: 90px;
}

.inner-padding.pos-left {
  padding: 90px;
  padding-left: 0;
}

.rtl .inner-padding.pos-left {
  padding-left: 90px;
  padding-right: 0;
}

.no-padding,
section.no-padding {
  padding: 0;
}

.float-left {
  float: left;
}

#loader-area {
  display: none;
  background: #fff;
}

#loader-area {
  display: none;
  background: #111;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.page-overlay {
  display: none;
  width: 120px;
  height: 120px;
  border-radius: 0px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  margin: auto;
}

.teaser {
  font-size: 16px;
  line-height: 1.8em;
  font-weight: 400;
}

.teaser.style-2 {
  letter-spacing: 20px;
  text-transform: uppercase;
}

.teaser.style-3 {
  font-size: 14px;
}

.teaser.style-4 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 14px;
}

.c-item_wrap img {
  width: 450px;
  height: 450px;
  margin: 0 auto;
}

.d-custom-nav {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100px;
  z-index: 100;
}

.d-arrow-left,
.d-arrow-right {
  display: none;
  cursor: pointer;
  position: absolute;
  margin-top: -10px;
  z-index: 100;
  width: 30px;
  height: 60px;
  background-color: rgba(255, 255, 255, 1);
}

.d-arrow-left {
  left: 0px;
  border-top-right-radius: 110px;
  border-bottom-right-radius: 110px;
}

.d-arrow-right {
  right: 0px;
  border-top-left-radius: 110px;
  border-bottom-left-radius: 110px;
}

.d-arrow-left i,
.d-arrow-right i {
  position: absolute;
  z-index: 1000;
  top: 20px;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
}

.d-arrow-left i {
  left: 8px;
}

.d-arrow-right i {
  right: 8px;
}

.de-box-image {
  padding: 10px;
  text-align: center;
}

.de-box-image img {
  margin-bottom: 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
  -moz-box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
  box-shadow: 0 0 40px 0 rgba(10, 10, 10, 0.05);
}

.de-box-image:hover img {
  margin-top: -20px;
  -webkit-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
  -moz-box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
  box-shadow: 0 40px 40px 0 rgba(10, 10, 10, 0.3);
}

.de-box-image:hover h4 {
  margin-top: 20px;
}

.de-image-hover {
  position: relative;
  overflow: hidden;
}

.dih-overlay {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--primary-color-rgb);
  opacity: 0;
}

.dih-title-wrap {
  z-index: 2;
  display: table;
  position: absolute;
  width: 100%;
  height: 100%;
}

.dih-title {
  font-family: var(--title-font);
  letter-spacing: 0.25rem;
  opacity: 0;
  font-size: 16px;
  z-index: 2;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  color: #fff;
}

.de-image-hover:hover .dih-overlay {
  opacity: 0.8;
}

.de-image-hover:hover .de-image-hover:hover .dih-title {
  opacity: 1;
  transform: scale(1.2);
}

.de-image-hover:hover img {
  transform: scale(1.1);
}

.de-image-hover:hover {
  -webkit-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 5px 25px 15px 0px rgba(0, 0, 0, 0.1);
}

.img-fullwidth {
  width: 100%;
  height: auto;
}

.de-box-a {
  position: relative;
}

.de-box-a .d-image {
  position: relative;
  z-index: 1;
}

.de-box-a .d-image img {
  object-fit: cover;
  object-position: center;
  height: 300px;
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
  padding: 10%;
}

.de-box-a .d-deco-1 {
  top: 0;
  z-index: 2;
  right: 0;
  position: absolute;
  width: 50%;
  height: 50%;
  border: solid 1px var(--primary-color);
}

.de-box-a .d-deco-2 {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 50%;
  height: 50%;
  border: solid 1px var(--primary-color);
}

.de-box-a .d-social {
  position: absolute;
  z-index: 1;
  left: 40px;
  bottom: 40px;
}

.de-box-a .d-social a {
  padding: 0 10px;
}

.d-sch-table {
  position: relative;
  padding: 15px;
  border: solid 1px var(--primary-color);
  margin-bottom: 10px;
}

.d-sch-table .d-content {
  font-family: var(--title-font);
  font-size: 30px;
  line-height: 42px;
  /* letter-spacing: 0.2em; */
  margin-top: 10px;
}

.d-sch-table .d-deco {
  position: absolute;
  width: 100%;
  height: 100%;
  border: solid 1px var(--primary-color);
  top: 10px;
  left: 10px;
}

.d-sch-table .d-col {
  border-bottom: solid 1px rgba(var(--primary-color-rgb), 0.5);
  padding: 15px 0;
  margin-bottom: 2px;
}

.d-sch-table .d-col:last-child {
  border-bottom: none;
}

.de-word {
  min-height: 80vh;
}

.de-word .letter {
  display: inline-block;
  margin: 0;
  min-width: 20px;
  letter-spacing: 10px;
}

/* price list */

.def-list-dots {
  padding-left: 30px;
  font-size: 18px;
}

.def-list-dots dl {
  display: table;
  width: 100%;
  margin: 3px 0;
  margin-bottom: 10px;
}

.def-list-dots dt {
  display: table-cell;
  width: 100%;
  max-width: 0;
  overflow: hidden;
  vertical-align: top;
  font-weight: 400;
}

.def-list-dots dt span:after {
  content: " ";
}

.def-list-dots dt:after {
  content: "..................................................................................................................................................................................................................";
  display: inline-block;
  width: 0;
  color: rgba(255, 255, 255, 0.5);
}

.def-list-dots .no-def-item dt:after {
  content: "";
}

.def-list-dots dd {
  display: table-cell;
  margin: 0;
  padding-left: 4px;
  vertical-align: bottom;
  white-space: nowrap;
  font-weight: 600;
  color: var(--primary-color);
}

.images-carousel-group {
  display: flex;
  width: calc(250px * 18);
  animation: scroll 40s linear infinite;
}

.images-carousel-group img {
  height: 350px;
  width: auto !important;
  margin: 0 auto;
}

.images-marquee-size img {
  height: 350px;
  width: auto !important;
  margin: 0 30px;
}

/* overwrite bootstrap */

.nav-pills .nav-link {
  font-weight: 500;
}

.de-preloader {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background: #ffffff;
  z-index: 5000;
}

.dark-scheme .de-preloader {
  background: var(--bg-color-even);
}

.dot-loader {
  width: 80px;
  height: 16.6666666667px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.dot-loader-dot {
  will-change: transform;
  height: 16.6666666667px;
  width: 16.6666666667px;
  border-radius: 50%;
  background-color: #ffffff;
  position: absolute;
  -webkit-animation: grow 0.5s ease-in-out infinite alternate;
  animation: grow 0.5s ease-in-out infinite alternate;
}

.dot-loader-dot.dot1 {
  left: 0;
  transform-origin: 100% 50%;
}

.dot-loader-dot.dot2 {
  left: 50%;
  transform: translateX(-50%) scale(1);
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

.dot-loader-dot.dot3 {
  right: 0;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

/* -----loader----- */

.preloader {
  background-color: var(--secondary-color);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
  margin: 0 auto;
}

.preloader .preloader-circle {
  width: 100px;
  height: 100px;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-top-color: var(--primary-color);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  z-index: 10;
  border-radius: 50%;
  -webkit-box-shadow: 0 1px 5px 0 var(--secondary-color-rgb);
  box-shadow: 0 1px 5px 0 var(--secondary-color-rgb);
  background-color: #f6f6f6;
  -webkit-animation: zoom 2000ms infinite ease;
  animation: zoom 2000ms infinite ease;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.preloader .preloader-circle2 {
  border-top-color: #0078ff;
}

.preloader .preloader-img {
  position: absolute;
  top: 50%;
  z-index: 200;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 6px;
  -webkit-transition: 0.6s;
  -o-transition: 0.6s;
  transition: 0.6s;
}

.preloader .preloader-img img {
  max-width: 100px;
}

.preloader .pere-text strong {
  font-weight: 800;
  color: var(--primary-color);
  text-transform: uppercase;
}

@-webkit-keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}

@keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}

/* ================================================== */

/* R E S P O N S I V E */

/* ================================================== */

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1300px;
  }

  .c-item_wrap img {
    width: 400px;
    height: 400px;
  }
}

@media (min-width: 980px) and (max-width: 1199px) {
  #mainmenu li a:after {
    margin-left: 15px;
  }

  .pf_full_width.gallery_border .item,
  .pf_full_width.gallery_border.pf_2_cols .item,
  .pf_full_width.gallery_border.pf_3_cols .item {
    float: left;
    width: 23.95%;
  }

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    min-width: 95%;
  }
}

/* -------------------------------------------------- 
    design for 768px
    /* ================================================== */

@media (max-width: 678px) {
  .custom-otp-input {
    width: 45px !important;
    height: 3rem;
    color: var(--primary-color);
    font-size: 20px;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    text-align: center;
    margin: 0 5px;
  }

  .swiper-button-next {
    width: 30px !important;
    height: 30px !important;
  }

  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .slider_text {
    padding-top: 0px;
  }
}

@media only screen and (max-width: 1090px) {
  #quick_search {
    width: 200px;
  }

  #quick_search {
    position: absolute;
    top: 30px;
  }

  .c-item_wrap img {
    width: 350px;
    height: 350px;
  }
}

@media only screen and (max-width: 992px) {
  header .logo-main {
    display: none;
  }

  header .logo-init {
    display: none;
  }

  header .logo-mobile {
    display: block;
  }

  .de_table .tr .td:nth-child(4),
  .de_table .tr .td:nth-child(5),
  .de_table .tr .td:nth-child(6),
  .de_table .tr .td:nth-child(7) {
    display: none;
  }

  .col-right {
    position: absolute;
    top: -82px;
    right: 60px;
    width: 140px;
  }

  .mb-sm-20 {
    margin-bottom: 20px;
  }

  .mb-sm-30 {
    margin-bottom: 30px;
  }

  .sm-mt-0 {
    margin-top: 0;
  }

  .p-sm-30 {
    padding: 40px;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .mb-sm-0 {
    margin-bottom: 0;
  }

  header {
    background: #101010 !important;
  }

  header .header-col.mid {
    position: absolute;
    top: 70px;
    height: 100vh;
    width: 100%;
    padding: 0 0 0 0;
    z-index: 100;
  }

  .text-center-sm {
    text-align: center;
  }

  .sm-pt10 {
    padding-top: 10px;
  }

  #menu-btn {
    display: block;
    float: right;
    z-index: 1000;
    top: 0;
    right: 0;
  }

  .inner-padding {
    padding: 40px;
  }

  .header-col-left,
  .header-col-right {
    padding-top: 16px;
  }

  .header-col-mid #mainmenu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: auto;
    padding: 10px 40px;
    background: #101010;
    transform: translatex(-100%);
    transition: all 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  }

  .header-col-mid #mainmenu.openMenu {
    transform: translateX(0%);
  }

  header {
    display: none;
  }

  header .info {
    display: none;
  }

  header.header-bg {
    background: rgba(0, 0, 0, 1);
  }

  header,
  header.fixed {
    display: block;
    position: inherit;
    margin: 0;
    padding: 0;
    margin-top: 0;
    height: auto;
    background: #111111;
  }

  #de-sidebar {
    position: absolute;
    width: 100%;
    right: 1px;
  }

  header.side-header,
  .de_light header.side-header {
    position: absolute;
    display: block;
    height: 60px;
    top: 0;
    background: #000;
  }

  header.side-header #mainmenu {
    position: absolute;
    background: #000;
    margin: 0;
    top: 60px;
    left: 0;
    width: 100%;
  }

  header.side-header #logo .logo {
    display: none;
  }

  header.side-header #logo .logo-2 {
    display: inline-block;
    position: absolute;
    height: 30px;
    top: 15px;
    left: 40px;
  }

  header.side-header #menu-btn {
    position: absolute;
    top: 8px;
    right: 30px;
    height: 0;
    z-index: 2000;
    height: 35px;
  }

  header.side-header #mainmenu li {
    display: block;
  }

  header.side-header #mainmenu a,
  .de_light.de-navbar-left header #mainmenu>li>a {
    display: block;
    text-align: center;
    color: #fff;
  }

  .de_light.de-navbar-left header #mainmenu>li {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }

  header.side-header #mainmenu a:hover {
    background: none;
  }

  header.side-header #mainmenu a:hover {
    background: none;
  }

  header.side-header .social-icons-2 {
    display: none;
  }

  header.autoshow {
    height: auto;
    top: 0;
  }

  header.autoshow #mainmenu li a {
    background: none;
  }

  header.header-mobile-sticky {
    position: fixed;
  }

  #subheader.s2 {
    padding: 40px 0 30px 0;
  }

  .de-navbar-left #subheader {
    padding: 0;
    margin: 0;
  }

  #subheader h1 {
    margin-top: 0;
  }

  #subheader span {
    display: none;
  }

  #subheader .crumb {
    display: none;
  }

  #content section:first-of-type {
    padding-top: 160px;
  }

  #sidebar {
    padding-left: 10px;
  }

  .de-navbar-left #subheader {
    padding-top: 70px;
  }

  .slider_text h1 {
    font-size: 32px;
  }

  .slider_text .description {
    display: none;
  }

  #logo,
  #logo .inner {
    vertical-align: middle;
    height: auto;
  }

  #domain-check .text,
  #domain-check .button {
    width: 100%;
  }

  .container-4 .de-team-list {
    width: 49.9%;
    float: left;
    position: relative;
  }

  #testimonial-full blockquote {
    padding: 20px;
  }

  nav {
    height: 0;
  }

  #mainmenu {
    position: fixed;
    height: 100%;
    z-index: 200;
    width: 200px;
    margin: 0;
    padding: 0;
    padding-top: 20px;
    overflow-y: auto;
    top: 100px;
  }

  #mainmenu ul {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  #mainmenu a.menu-item {
    text-align: left;
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #mainmenu li.has-child:after {
    display: none;
  }

  #mainmenu li li a.menu-item,
  #mainmenu li li li a.menu-item {
    padding-left: 0;
  }

  #mainmenu a.menu-item:hover {
    background: #111;
  }

  #mainmenu a.menu-item:hover {
    background: #111;
  }

  #mainmenu li ul {
    display: block;
    position: inherit;
    margin: 0;
    width: 100%;
  }

  #mainmenu li {
    margin: 0;
    width: 100%;
    display: block;
  }

  #mainmenu li a.menu-item:after {
    display: none;
  }

  #mainmenu li:last-child {
    margin-bottom: 30px;
  }

  #mainmenu li ul {
    border-top: solid 1px #eee;
    top: auto;
    width: auto;
    height: auto;
    position: inherit;
    visibility: visible;
    opacity: 1;
  }

  #mainmenu>li.menu-item-has-children>a.menu-item {
    position: relative;
    padding-right: 15px;
  }

  #mainmenu>li.menu-item-has-children>a.menu-item:after {
    content: "\f107";
    font-family: "FontAwesome";
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
  }

  #mainmenu li ul a.menu-item {
    width: 100%;
    background: none;
    border: none;
  }

  #mainmenu li ul li {
    border: none;
    padding-left: 40px;
  }

  #mainmenu li ul li a.menu-item {
    display: block;
  }

  #mainmenu li ul li:last-child {
    border-bottom: none;
    margin: 0;
  }

  #mainmenu li ul li:last-child a.menu-item {
    border-bottom: none;
  }

  #mainmenu li a {
    color: #eceff3;
  }

  #mainmenu li li a.menu-item:hover {
    color: #fff;
  }

  #mainmenu ul:not(.mega) {
    border: none;
  }

  #mainmenu>li ul.mega .sb-menu {
    border: none;
  }

  #back-to-top {
    display: none;
  }

  header.header-mobile {
    overflow: hidden;
    position: relative;
  }

  header.header-mobile #mainmenu {
    width: 100%;
    padding-right: 30px;
  }

  header.header-mobile #mainmenu ul {
    background: none;
  }

  header.header-mobile #mainmenu a.menu-item {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  header.header-mobile #mainmenu a.menu-item:hover {
    background: none;
  }

  header.header-mobile #mainmenu li ul {
    display: block;
    position: inherit;
    margin: 0;
    width: 100%;
  }

  header.header-mobile #mainmenu li {
    border-bottom: solid 1px #eee;
    margin: 0;
    width: 100%;
    display: block;
  }

  .dark-scheme header.header-mobile #mainmenu li {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }

  header.header-mobile #mainmenu li ul.mega ul {
    height: auto;
  }

  header.header-mobile #mainmenu li ul.mega .menu-content {
    background: #fff;
  }

  header.header-light.header-mobile #mainmenu li,
  header.header-light.header-mobile #mainmenu li ul li a.menu-item {
    border-color: #eee;
  }

  header.header-dark.header-mobile {
    background: #222;
  }

  header.header-dark.header-mobile #mainmenu li {
    border-color: #333;
  }

  header.header-dark.header-mobile #mainmenu li a.menu-item {
    color: #fff;
  }

  header.header-mobile #mainmenu li a.menu-item:after {
    display: none;
  }

  header.header-mobile #mainmenu li:last-child {
    margin-bottom: 30px;
  }

  header.header-mobile #mainmenu li ul {
    border-top: none;
    top: auto;
  }

  header.header-mobile #mainmenu li ul a.menu-item {
    width: 100%;
    background: none;
    border: none;
  }

  header.header-mobile #mainmenu li ul li {
    border: none;
    padding-left: 40px;
  }

  header #mainmenu li ul li:first-child {
    border-top: solid 1px #eee;
  }

  .dark-scheme header #mainmenu li ul li:first-child {
    border-top-color: rgba(255, 255, 255, 0.1);
  }

  header.header-mobile #mainmenu li ul li a.menu-item {
    display: block;
    border-bottom: solid 1px #eee;
  }

  header.header-mobile #mainmenu li ul li:last-child {
    border-bottom: none;
    margin: 0;
  }

  header.header-mobile #mainmenu li ul li:last-child a.menu-item {
    border-bottom: none;
  }

  header.header-mobile #mainmenu li a.menu-item {
    color: #888;
  }

  .dark-scheme header.header-mobile #mainmenu li a.menu-item {
    color: #ffffff;
  }

  header.header-mobile #mainmenu li a.menu-item:hover {
    color: #333;
  }

  .dark-scheme header.header-mobile #mainmenu li a.menu-item:hover {
    color: #fff;
  }

  header.header-mobile #mainmenu li li a.menu-item:hover {
    color: #888;
  }

  header.header-mobile #mainmenu li ul {
    height: 0;
    overflow: hidden;
    position: relative;
    left: 0;
  }

  header.header-mobile #mainmenu li ul li ul {
    margin-bottom: 10px;
  }

  header.header-mobile #mainmenu>li>span {
    width: 36px;
    height: 36px;
    /* background: url(../images/ui/arrow-down.png) center no-repeat; */
    position: absolute;
    right: 0;
    margin-top: 10px;
    z-index: 1000;
    cursor: poInter;
  }

  .dark-scheme header.header-mobile #mainmenu>li>span {
    /* background: url(../images/ui/arrow-down-light.png) center no-repeat; */
  }

  header.header-mobile #mainmenu li span.active {
    /* background: url(../images/ui/arrow-up.png) center no-repeat; */
  }

  .dark-scheme header.header-mobile #mainmenu li span.active {
    /* background: url(../images/ui/arrow-up-light.png) center no-repeat; */
  }

  header.header-mobile #mainmenu li>ul>li>span {
    width: 36px;
    height: 36px;
    /* background: url(../images/ui/arrow-down.png) center no-repeat; */
    position: absolute;
    right: 0;
    margin-top: -45px;
    z-index: 1000;
    cursor: poInter;
  }

  header.header-mobile #mainmenu>li>ul>li>span.active {
    /* background: url(../images/ui/arrow-up.png) center no-repeat; */
  }

  header.header-mobile #mainmenu li ul li a.menu-item {
    border-bottom: solid 1px #eee;
  }

  .dark-scheme header.header-mobile #mainmenu li ul li a.menu-item {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }

  header.header-mobile #mainmenu li ul li:last-child>a.menu-item {
    border-bottom: none;
  }

  header.header-mobile .h-phone {
    color: #202020;
  }

  header.header-light.header-mobile #mainmenu>li>span,
  header.header-light.header-mobile #mainmenu li>ul>li>span {
    /* background: url(../images/ui/arrow-down.png) center no-repeat; */
  }

  header.header-light.header-mobile #mainmenu>li>span.active,
  header.header-light.header-mobile #mainmenu li>ul>li>span.active {
    /* background: url(../images/ui/arrow-up.png) center no-repeat; */
  }

  #mainmenu li ul.mega {
    position: fixed;
    left: 0;
    width: 100%;
    margin-top: 30px;
    display: block;
  }

  #mainmenu li ul.mega>li {
    width: 100%;
  }

  #mainmenu li ul.mega>li ul {
    position: static;
    visibility: visible;
    opacity: 1;
    left: 0;
    float: none;
    width: 100%;
  }

  #mainmenu ul.mega>li ul li {
    margin: 0;
    padding: 0;
  }

  #mainmenu li ul.mega .menu-content {
    background: #202020;
    padding: 30px;
  }

  /* header light */
  .header-light #mainmenu ul li a.menu-item,
  .header-light #mainmenu ul li a.menu-item:hover {
    color: #333;
  }

  .de_tab.tab_methods .de_nav {
    text-align: center;
  }

  .de_tab.tab_methods .de_nav li {
    margin: 5px;
    padding: 0;
  }

  .de_tab.tab_methods .de_nav li span {
    margin: 0;
    display: inline-block;
  }

  .de_tab.tab_methods .de_nav li .v-border {
    border: none;
  }

  .hidden-phone {
    display: none;
  }

  .grid_gallery .item {
    width: 100%;
  }

  .sm-hide {
    display: none;
  }

  .btn-rsvp {
    position: absolute;
  }

  .d-btn-close {
    position: relative;
  }

  .schedule-item .sc-info {
    padding-top: 20px;
  }

  .de_tab.tab_style_4 .de_nav li {
    min-width: 100px;
  }

  #mainmenu a.menu-item span {
    display: none;
  }

  .pf_full_width.grid {
    margin-left: 1.5%;
  }

  .de_tab.tab_methods.style-2 .de_nav li.active .arrow {
    margin-top: -40px;
    margin-bottom: 40px;
  }

  .table-custom-1 {
    position: relative;
    height: auto;
  }

  h1.very-big,
  .h1.very-big {
    font-size: 9vw;
    line-height: 9vw;
    letter-spacing: -0.25vw;
  }

  h1.ultra-big span,
  .h1.ultra-big span {
    font-size: 15vw;
    line-height: 15vw;
    letter-spacing: 1px;
    padding: 0;
  }

  .timeline>li>.timeline-panel {
    width: 100%;
  }

  .timeline-badge {
    display: none;
  }

  .timeline:before {
    background: none;
  }

  .tp-caption {
    padding-left: 40px;
    padding-right: 40px;
  }

  .author_list {
    -moz-column-count: 3;
    -moz-column-gap: 20px;
    -webkit-column-count: 3;
    -webkit-column-gap: 20px;
    column-count: 3;
    column-gap: 20px;
  }

  .subfooter .de-flex,
  .subfooter .de-flex-col {
    display: block;
  }

  .subfooter .social-icons {
    margin-top: 20px;
  }

  .subfooter span.copy {
    margin-top: 20px;
    display: block;
  }

  .activity-filter li {
    width: 100%;
  }

  .dropdown {
    display: inline-block;
    margin-bottom: 10px;
  }

  .side-bg .image-container {
    position: relative;
    width: 100%;
    height: 400px !important;
    top: 0;
    margin-bottom: 60px;
  }

  .side-bg-text {
    margin-bottom: 80px;
  }
}

/* -------------------------------------------------- 
    custom for 320px & 480px 
/* -------------------------------------------------- */

@media only screen and (max-width: 767px) {

  h1,
  #subheader h1 {
    font-size: 42px;
    line-height: 1.3em;
  }

  h2 {
    font-size: 36px;
    line-height: 1.3em;
  }

  #subheader h1.blog-title {
    font-size: 32px;
    margin-bottom: 30px;
    letter-spacing: 6px;
  }

  p.lead.big {
    font-size: 26px !important;
  }

  .de-marquee-list .d-item-txt {
    font-size: 84px;
  }

  .de-marquee-list .d-item-block {
    width: 40px;
    transform: translateY(-30px);
  }

  .d-sch-table .d-content {
    font-size: 22px;
  }

  .c-item_title {
    font-size: 24px;
  }

  .de-blog-c1 .d-image {
    padding-left: 0;
    margin-bottom: 20px;
  }

  .de-blog-c1 .d-date {
    background: #101010;
    padding: 10px;
    padding-bottom: 20px;
  }

  .de-table.table-rank thead th:nth-child(5),
  .de-table.table-rank thead th:nth-child(6),
  .de-table.table-rank thead th:nth-child(7),
  .de-table.table-rank tbody td:nth-child(4),
  .de-table.table-rank tbody td:nth-child(5),
  .de-table.table-rank tbody td:nth-child(6) {
    display: none;
  }

  .d_profile {
    display: block;
  }

  .d_profile .de-flex-col {
    display: block;
  }

  .profile_avatar {
    display: block;
  }

  .profile_avatar i {
    margin-top: -40px;
  }

  .profile_name {
    margin-left: 0;
    margin-top: 20px;
    display: block;
    width: 100%;
  }

  .profile_follow {
    margin-top: 40px;
    width: 100%;
  }

  .menu_side_area {
    margin-left: 20px;
    width: 100%;
    position: relative;
    right: 0;
    text-align: right;
    z-index: 0;
    display: block;
  }

  header .container {
    min-width: 100%;
  }

  .pricing-2-col .pricing-box,
  .pricing-3-col .pricing-box,
  .pricing-4-col .pricing-box,
  .pricing-5-col .pricing-box {
    width: 100%;
  }

  .container-4 .de-team-list {
    width: 100%;
    float: left;
    position: relative;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .feature-box-image,
  .pricing-box {
    margin-bottom: 30px;
  }

  #filters li.pull-right {
    float: none;
  }

  .pf_full_width .item {
    float: left;
    width: 49.9%;
  }

  .text-item {
    font-size: 48px;
    line-height: 48px;
  }

  .text-item i {
    font-size: 36px;
  }

  #de-sidebar {
    width: 100%;
    display: block;
    position: relative;
  }

  .gallery_border {
    margin-left: 0px;
  }

  .pf_full_width.gallery_border .item {
    width: 48.9%;
  }

  body.boxed {
    margin: 0px;
  }

  .schedule-item .sc-time {
    width: 100%;
    padding-bottom: 20px;
    float: none;
  }

  .schedule-item .sc-pic {
    width: 80px;
  }

  .schedule-item .sc-pic img {
    width: 60px;
  }

  .schedule-item .sc-name {
    padding-top: 5px;
  }

  .schedule-item .sc-info {
    float: none;
    display: block;
    width: 100%;
    clear: both;
    padding-top: 30px;
  }

  .schedule-item .sc-info h4 {
    margin-bottom: 10px;
  }

  .de_tab.tab_style_4 .de_nav {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  .de_tab.tab_style_4 .de_nav li {
    min-width: 33.3%;
    width: 33.3%;
    border: none;
  }

  .de_tab.tab_style_4 .de_nav li:first-child,
  .de_tab.tab_style_4 .de_nav li:last-child {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
  }

  .pf_full_width.grid {
    margin-left: 0.2%;
  }

  .pf_full_width.grid .item {
    width: 48.5%;
  }

  .display-table [class*="col-"] {
    display: table;
  }

  h1.big,
  h1_big {
    font-size: 8vw;
    line-height: 10vw;
  }

  .xs-hide {
    display: none;
  }

  .author_list {
    -moz-column-count: 2;
    -moz-column-gap: 20px;
    -webkit-column-count: 2;
    -webkit-column-gap: 20px;
    column-count: 2;
    column-gap: 20px;
  }
}

/* custom css for gallery section */

.gallery-img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 440px;
}

@media (max-width: 1280px) {
  .gallery-img {
    height: 350px;
  }
}

@media (max-width: 1024px) {
  .gallery-img {
    height: 350px;
  }
}

@media (max-width: 768px) {
  .gallery-img {
    height: 300px;
  }
}

.service-img {
  object-position: center;
  object-fit: cover;
  height: 80px;
  width: 80px;
}

.team-img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 400px;
}

/* -------------------------------------------------- 
    design for 480px
    /* -------------------------------------------------- */

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .feature-box-image,
  .pricing-box {
    margin-bottom: 30px;
  }

  .timeline-panel {
    margin-bottom: 30px;
  }

  .timeline-pic {
    height: 300px;
  }
}

/* -------------------------------------------------- 
    design for 320px
    /* -------------------------------------------------- */

@media only screen and (max-width: 480px) {
  .pf_full_width .item {
    float: left;
    width: 100%;
  }

  .text-item {
    font-size: 32px;
    line-height: 32px;
  }

  .text-item i {
    font-size: 24px;
  }

  .pf_full_width.gallery_border .item {
    width: 100%;
  }

  h4.s2 span {
    display: block;
    margin: 0;
    padding: 0;
  }

  .h-phone {
    display: none;
  }

  .author_list {
    -moz-column-count: 1;
    -moz-column-gap: 20px;
    -webkit-column-count: 1;
    -webkit-column-gap: 20px;
    column-count: 1;
    column-gap: 20px;
  }

  header a.btn-main i {
    display: inline-block;
  }

  #form_quick_search {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 360px) {
  #topbar {
    display: none;
  }
}

/* section control */
@media only screen and (min-device-width: 768) and (max-device-width: 1024) and (orientation: portrait) {
  section {
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    -o-background-size: auto 100%;
    background-size: auto 100%;
    background-attachment: scroll;
  }
}

@media only screen and (max-width: 992px) {
  #subheader {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll;
  }

  section {
    -webkit-background-size: auto 100%;
    -moz-background-size: auto 100%;
    -o-background-size: auto 100%;
    background-size: auto 100%;
    background-attachment: scroll;
  }
}

@media (min-width: 1200px) {
  .container-timeline ul {
    display: flex;
    padding-left: 0;
    padding-top: 20px;
  }

  .container-timeline ul li {
    flex: 1 1 0;
    border-left: 0;
    border-top: 1px solid green;
    padding: 50px 4em 0 0;
  }

  .container-timeline ul li::before {
    left: 0;
  }
}

/*  */

@media (min-width: 1280px) {
  .media-1280 {
    display: inline-block !important;
  }
}

/*  */

.bg-primary {
  background-color: var(--primary-color) !important;
}

.waitlist {
  height: 60vh;
}

.calender__container {
  position: relative;
}

.calender__wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ccc;
}

.calendar__button {
  border: none;
  background: none;
  color: #fff;
  text-transform: uppercase;
}

.calendar__button span {
  margin-left: 8px;
  font-size: 20px;
}

.calendar__button:hover {
  border-bottom: 1px solid #fff;
}

.slider__date {
  position: relative;
}

.slider__date input[type="radio"] {
  display: none;
}

.slider__date label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: transparent;
  border: 2px solid var(--primary-color);
}

.slider__date input[type="radio"]:checked+label {
  background-color: var(--primary-color);
  opacity: 1;
}

.time__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.time__container .time__wrapp {
  position: relative;
  border: 1px solid var(--primary-color);
  text-align: center;
}

.time__container .time__wrapp label {
  width: 100%;
  height: 100%;
  padding: 10px;
  text-align: start;
  cursor: pointer;
  transition: 0.4s ease-in-out 0s;
  font-size: 17px;
  font-weight: 700;
  background: transparent;
}

.time__container .time__wrapp label:after,
.time__container .time__wrapp label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.time__container .time__wrapp input[type="radio"] {
  display: none;
}

.time__container .time__wrapp label:after {
  height: 20px;
  width: 20px;
  border: 2px solid var(--primary-color);
  right: 5px;
  top: calc(50% - 10px);
}

.time__container .time__wrapp label:before {
  background: #fff;
  height: 18px;
  width: 18px;
  right: 5px;
  top: 50%;
  top: calc(50% - 9px);
  transform: scale(5);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out 0s;
}

.time__container .time__wrapp input[type="radio"]:checked+label {
  background: var(--primary-color);
}

.time__container .time__wrapp input[type="radio"]:checked~label::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

@media (max-width: 678px) {
  .time__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .data-and-btn {
    margin-right: 40px;
  }

  .swiper-button-prev {
    width: 30px !important;
    height: 30px !important;
  }

  .swiper-button-next {
    width: 30px !important;
    height: 30px !important;
  }
}

@keyframes scroll {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(calc(-250px * 9));
  }
}

#contact_form input::placeholder,
#contact_form textarea::placeholder {
  color: white;
}

.transperant-bg {
  background-color: transparent !important;
  border-color: var(--primary-color);
  color: white;
  margin-bottom: 10px;
}

.modal-height {
  height: 70vh;
  width: auto;
}
